// import { redirect } from "react-router-dom";
import { deleteGlobalRace } from '../utils/api';

export async function action({ params }) {

    const numDeleted = await deleteGlobalRace({race_id: params.race_id});
    // console.log(numDeleted.err);
    // throw new Error('My test error');
    // return redirect('/races');
    let msg = {status: '', code: 0}
    if (numDeleted?.err) {
        if (numDeleted.err.code === '23503') {
            msg.code = 23503;
            msg.status = 'You must delete all results before deleting a race.';
        } else {
            msg.status = 'Unable to delete race';
            msg.code = 500;
        }
    }
    return msg;

}

export default function DeleteRacePage () {

    return ( <div>Error Happened</div>);
}
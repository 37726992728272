import { Link, redirect, useLoaderData } from "react-router-dom";
import { useEffect } from "react";
import StandingDetailHeader from "../components/StandingDetailHeader";
import StandingDetailResults from "../components/StandingDetailResults";
import AvgLineChart from "../components/AvgLineChart";
import { getParticipantResultDetails, getParticipantOverallInfo, getAvgRaceTimeByGender, getSeriesCatName } from "../utils/api";

export default function StandingDetailPage() {

    const { details, overallInfo, avgGenderTime, series_id, seriesCatName } = useLoaderData();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "instant"
        });
    }, []);

    return (
        <>
            <div className='row mt-3'>
                <div className='col-xs-3 col-xl-3'></div>
                <div className='d-flex justify-content-center align-items-center col-xs-3 col-xl-6'>
                    {/* <img src='/2023_rrs_logo.png' className='img-fluid hermes-logo-img' alt='Road Race Series Logo'></img> */}
                    {seriesCatName.series_name.includes("Road") ? 
                    <img src='/RRSHermesLogo.png' className='img-fluid hermes-logo-img' alt='Road Race Series Logo'></img>
                    : <img src='/fullmoon.png' className='img-fluid hermes-logo-img' alt='Full Moon Trail Series Logo'></img>
                }
                </div>
                <div className='col'></div>
            </div>
            <div className='row mt-3'>
                <div className='col-xs-3 col-xl-3'></div>
                <div className='d-flex justify-content-center align-items-center col-xs-3 col-xl-6'>
                {seriesCatName.series_name.includes("Road") ? 
                <>
                    {/* <a className='btn btn-hermes me-2' target='_blank' rel='noreferrer' href={`https://runsignup.com/Race/OH/Cleveland/RoadRaceSeries`}>Registration</a> */}
                    <a className='btn btn-hermes' target='_blank' rel='noreferrer' href={`https://www.roadraceseries.com/`}>More Info</a>
                </>
                : <> 
                    {/* <a className='btn btn-hermes me-2' target='_blank' rel='noreferrer' href={`https://fullmoontrailseries.com/Race/Register`}>Registration</a> */}
                    <a className='btn btn-hermes' target='_blank' rel='noreferrer' href={`https://www.fullmoontrailseries.com/Race/FullMoon/Page-6`}>More Info</a>
                </>  

                }
                </div>
                <div className='col'></div>
            </div>
            <div className="row py-3">
                <div className="col-12 col-xl-2"></div>
                <div className="col-12 col-xl-8 mx-auto">
                    <nav aria-label="breadcrumb" className="mt-2">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={`/standings/${series_id}`}>Standings</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Details</li>
                        </ol>
                    </nav>
                </div>
                <div className="col-12 col-xl-2"></div>
            </div>
            <div className="row">
                <div className="col-12 col-xl-2"></div>
                <div className="col-12 col-xl-8">
                    <StandingDetailHeader props={overallInfo} showAgeGroups={seriesCatName.show_age_groups} />
                </div>
                <div className="col-12 col-xl-2"></div>
            </div>

            <div className="row mt-4">
                <div className="col-12 col-xl-2"></div>
                <div className="col-12 col-xl-8 p-3 mx-auto">
                    <StandingDetailResults props={details.success} showAgeGroups={seriesCatName.show_age_groups} showAgeGrade={seriesCatName.show_age_grade}  />
                </div>
                <div className="col-12 col-xl-2"></div>
            </div>
            <div className="row mt-4">
                <div className="col-12 col-xl-2"></div>
                <div className="col-12 col-xl-8 p-3 mx-auto">
                    <AvgLineChart props={[details.success, avgGenderTime.success]} />
                </div>
                <div className="col-12 col-xl-2"></div>
            </div>
        </>
    )
}

export async function loader({ request, params }) {

    // standings will be default route initially.
    const series_id = params.series_id || 1;
    const participant_series_id = params.participant_series_id;
    // const url = new URL(request.url);
    // const seriesYear = url.searchParams.get("seriesYearSelect");

    try {
        // const categoryList = await getListAgeGroupsAndGenders(series_id);
        // const standings = await getSeriesStandings(series_id, categorySelect);
        const overallInfo = await getParticipantOverallInfo(series_id, participant_series_id);
        const details = await getParticipantResultDetails(series_id, participant_series_id);
        const avgGenderTime = await getAvgRaceTimeByGender(series_id);
        const seriesCatName = await getSeriesCatName(series_id);
        return { details, overallInfo, avgGenderTime, series_id, seriesCatName };
    } catch (err) {
        if (err.status === 401) {
            return redirect('/login');
        }
        return err;
    }
}
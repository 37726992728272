import { Form, redirect, useActionData, useNavigation } from 'react-router-dom';
import { postLoginUser, logoutUser } from '../utils/api';



export default function LoginPage() {
    
    const data = useActionData();
    const navigation = useNavigation();

    return (  
        <> 
        <nav className="navbar navbar-expand-lg nav-bar-header sticky-top" id='myNavbar'>
        <div className="container-fluid">
        <a className="navbar-brand nav-bar-brand" href="/">Series Results</a>

        </div>
    </nav>    
    <div className='container-fluid'>   
        <Form method="post">
            <div className="row g-3 mt-2 justify-content-center">
                <div className="col-md-4"></div>
                <div className="col-md-3">
                <p className='fs-5'>Administrator Login</p>
                {data && data.status && 
                    <div className="mb-3">
                            {/* <label htmlFor="userInputEmail" className="form-label">Email Address</label> */}
                            <input type={"text"} className="form-control text-danger border border-danger" disabled id="msg" name="msg" value={data.message}></input>
                    </div>
                }
                    <div className="mb-3">
                        <label htmlFor="userInputEmail" className="form-label">Email Address</label>
                        <input type={"email"} className="form-control" id="userInputEmail" name="userInputEmail"></input>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="userInputPassword" className="form-label">Password</label>
                        <input type={"password"} className="form-control" id="userInputPassword" name="userInputPassword"></input>
                    </div>
                    <button type="submit" disabled={navigation.state === 'submitting'} className="btn btn-primary btn-hermes">Login</button>
                </div>
                <div className="col-md-4"></div>
            </div>
        </Form>
        </div> 
    </>
    );
}

export async function loader() {
    try {
        const response = await logoutUser();
        if (response.status === 200) {
            localStorage.setItem('authInfo', null);
        }
    } catch (err) {
        localStorage.setItem('authInfo', null);
        return err;
    }
}

export async function action ({ request }) {

    const formData = await request.formData();
    const post = {
        user_email: formData.get('userInputEmail'),
        user_password: formData.get('userInputPassword')
    }

    try {
        const loggedInUser = await postLoginUser(post);
        await setLocalStorage(loggedInUser);
        return redirect('/');
    } catch (err) {
        if (err.status >= 400) {
            return err;
        }
    }
}

function setLocalStorage(loggedInUser) {
    return new Promise((resolve, reject) => {
        const now = new Date();
        now.setDate(now.getDate() + 7 );
        const item = {
            userEmail: loggedInUser.success,
            expiry: now.toLocaleString('en-US', {timeZone: 'America/New_York',}),
            isAdmin: loggedInUser.is_admin
        }
        resolve(localStorage.setItem('authInfo', JSON.stringify(item)));
    })
}

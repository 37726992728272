import { useRouteError } from 'react-router-dom';

export default function ErrorPage() {
    
    const error = useRouteError();

    return (
        <div className='container-fluid'>
            <div className='d-flex flex-column align-items-center justify-content-center mt-5'>
                <div>
                    <h3>Sorry, An unexpected error occurred</h3>
                </div>
                <div>
                    <p>Please try refreshing your browser</p>
                </div>
                <div>
                <p className='text-muted'>
                    <i>{error.statusText || error.message}</i>
                </p>
                </div>
                <div>
                    <p style={{fontSize:'.90rem'}}>Still having trouble? Please email: support@greyghosttech.com with a description of what action you were trying to perform.</p>
                </div>

            </div>
        </div>
    );
}
import { Form, useLoaderData, useActionData, useNavigation, Link, redirect } from 'react-router-dom';
import { getSeriesInfo, updateSeriesInfo } from '../utils/api';
import { formatDateString } from '../utils/helpers';
import MsgComponent from '../components/MsgComponent';



export default function EditSeriesPage() {
    
    const data = useActionData();
    const navigation = useNavigation();
    const loaderData = useLoaderData();


    return (    
        <Form method="post">
            <div className="row g-3 mt-2 justify-content-start">
                <div className='col-md-4'></div>
                <div className='col-md-3 ps-4'><Link className='hermes-link-color' to={"/admin/series"}>Back</Link></div>
                <div className='col-md-4'></div>
            </div>
            <div className="row g-3 mt-2 justify-content-center">
            <h2 className='text-center hermes-header'>Edit Series</h2>
                <div className="col-md-4"></div>
                <div className="col-md-3">
                { data?.code >= 400 ? <MsgComponent msg={data.status} /> : ''}
                    <div className="mb-3">
                        <label htmlFor="seriesName" className="form-label">Series Name <span style={{color: 'red'}}>*</span></label>
                        <input type={"text"} className="form-control" id="seriesName" name="seriesName" required defaultValue={loaderData.seriesList[0].series_name}></input>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="seriesAgeDate" className="form-label">Series Age Date <span style={{color: 'red'}}>*</span></label>
                        <input type={"date"} className="form-control" id="seriesAgeDate" name="seriesAgeDate" required defaultValue={formatDateString(loaderData.seriesList[0].series_age_date)}></input>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="seriesStartDate" className="form-label">Series Start Date <span style={{color: 'red'}}>*</span></label>
                        <input type={"date"} className="form-control" id="seriesStartDate" name="seriesStartDate" required defaultValue={loaderData.seriesList[0].series_start_date ? formatDateString(loaderData.seriesList[0].series_start_date) : ''}></input>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="seriesEndDate" className="form-label">Series End Date <span style={{color: 'red'}}>*</span></label>
                        <input type={"date"} className="form-control" id="seriesEndDate" name="seriesEndDate" required defaultValue={loaderData.seriesList[0].series_start_date ? formatDateString(loaderData.seriesList[0].series_end_date) : ''}></input>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="minNumberRaces" className="form-label">Mininum Number of Races: <span style={{color: 'red'}}>*</span></label>
                        <input type={"number"} className="form-control" id="minNumberRaces" name="minNumberRaces" required defaultValue={loaderData.seriesList[0].min_races}></input>
                    </div>

                    <button type="submit" disabled={navigation.state === 'submitting'} className="btn btn-primary btn-hermes">Update Series</button>
                </div>
                <div className="col-md-4"></div>
            </div>
        </Form>
    );
}

export async function action ({ request, params }) {


    const formData = await request.formData();
    const post = {
        series_name: formData.get('seriesName'),
        series_age_date: formData.get('seriesAgeDate'),
        series_start_date: formData.get('seriesStartDate'),
        series_end_date: formData.get('seriesEndDate'),
        min_number_races: formData.get('minNumberRaces')
    }

    try {
        const series_id = params.series_id;
        post.series_id = series_id;
        await updateSeriesInfo(post);
        return redirect('/admin/series');
    } catch (err) {
        console.log(err);
        return err;
    }

}

export async function loader ({params}) {

    const series_id = params.series_id;
    try {
        const seriesList = await getSeriesInfo(series_id);
        return {seriesList: seriesList};
    } catch (err) {
        if (err.status === 401 ) {
            return redirect('/login');
        }
        return err; 
    }


}



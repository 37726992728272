import { formatDateString } from '../utils/helpers';
import { useFetcher, useNavigation } from 'react-router-dom';
import { useState } from 'react';

export default function ParticipantList(props) {

    const fetcher = useFetcher();
    const navigation = useNavigation();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [gender, setGender] = useState('');
    const [dob, setDob] = useState();
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [registrationDate, setRegistrationDate] = useState('');
    const [participantId, setParticipantId] = useState('');
    const [participantSeriesId, setParticipantSeriesId] = useState('');

    const editRace = (e, participant) => {
        setFirstName(participant["First Name"]);
        setLastName(participant["Last Name"]);
        setDob(formatDateString(participant["DOB"]));
        setCity(participant["city"]);
        setState(participant["state"]);
        setGender(participant["Gender"]);
        setRegistrationDate(participant["registration_date"]);
        setParticipantId(participant["participant_id"]);
        setParticipantSeriesId(participant["SeriesId"]);
    }

    // const updateParticipant= (e) => {

    // }
    
    return (
        <>
        <div className="table-responsive mt-4">
            <table className="table table-bordered table-striped caption-top">
                <caption className='mb-2'>
                    List of Series Participants 
                </caption>
                <thead>
                    <tr>
                        <th scope="col">Series ID</th>
                        <th scope="col">First Name</th>
                        <th scope="col">Last Name</th>
                        <th scope="col">Gender</th>
                        <th scope="col">DOB</th>
                        <th scope="col">City</th>
                        <th scope="col">State</th>
                        <th scope="col">Registration Date</th>
                        <th scope="col">Series Name</th>
                        <th scope='col'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {props.props.map((participant) => 
                     <tr key={participant.participant_id}>
                        {/* <td>{participant["participant_id"]}</td> */}
                        <td>{participant["SeriesId"]}</td>
                        <td>{participant["First Name"]}</td>
                        <td>{participant["Last Name"]}</td>
                        <td>{participant["Gender"]}</td>
                        <td>{formatDateString(participant["DOB"])}</td>
                        <td>{participant["city"]}</td>
                        <td>{participant["state"]}</td>
                        <td>{participant["registration_date"]}</td>
                        <td>{participant["series_name"]}</td>
                        {/* <td><button className='btn btn-hermes' onClick={(e) => editRace(e, participant)}>Edit</button></td> */}
                        {/* <button type="button" className="btn-close btn-hermes" data-bs-toggle="modal" data-bs-target="#editParticipantModal" 
                        onClick={(e) => editRace(e, participant)} aria-label="Close"></button> */}
                        <td>
                            <button type='button' className='btn btn-hermes mx-2 btn-sm' data-bs-toggle="modal" data-bs-target="#editParticipantModal" onClick={(e) => editRace(e, participant)}  >
                            Edit </button>
                        </td>
                    </tr>
                    )}
                </tbody>
            </table>
        </div>
        <div className="modal" id='editParticipantModal' tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" style={{color: 'rgba(60,106,176,0.9)'}}>Edit Participant</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <fetcher.Form
                        method="post" action={`/admin/update-participant`}
                        // onSubmit={((event) =>  updateParticipant(event))} 
                    >
                        <div className="mb-3">
                            <label htmlFor="first_name" className="form-label">First Name <span style={{color: 'red'}}>*</span></label>
                            <input type={"text"} className="form-control" id="first_name" name="first_name" required value={firstName || ''}
                            onChange={e => setFirstName(e.target.value)}></input>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="last_name" className="form-label">Last Name <span style={{color: 'red'}}>*</span></label>
                            <input type={"text"} className="form-control" id="last_name" name="last_name" 
                            required value={lastName || ''}
                                onChange={e => setLastName(e.target.value)}>
                            </input>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="gender" className="form-label">Gender <span style={{color: 'red'}}>*</span></label>
                            <input type={"text"} className="form-control" id="gender" name="gender" 
                            required value={gender || ''}
                                onChange={e => setGender(e.target.value)}>
                            </input>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="dob" className="form-label">DOB <span style={{color: 'red'}}>*</span></label>
                            <input type={"date"} className="form-control" id="dob" name="dob" required value={dob || ''}
                            onChange={e => setDob(e.target.value)}>
                            </input>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="city" className="form-label">City <span style={{color: 'red'}}>*</span></label>
                            <input type={"text"} className="form-control" id="city" name="city" required value={city || ''}
                                onChange={e => setCity(e.target.value)}>
                            </input>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="state_name" className="form-label">State <span style={{color: 'red'}}>*</span></label>
                            <input type={"text"} className="form-control" id="state_name" name="state_name" required value={state || ''}
                            onChange={e => setState(e.target.value)}>
                            </input>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="registration_date" className="form-label">Registration Date <span style={{color: 'red'}}>*</span></label>
                            <input type={"date"} className="form-control" id="registration_date" name="registration_date" required value={registrationDate || ''}
                            onChange={e => setRegistrationDate(e.target.value)}>
                            </input>
                            <input type={"text"} id="participant_id" name="participant_id" hidden value={participantId} readOnly />
                            <input type={"text"} id="participant_series_id" name="participant_series_id" hidden value={participantSeriesId} readOnly />

                        </div>         
                        <button type="submit" className='btn btn-hermes me-3' disabled={navigation.state === 'submitting'}  data-bs-dismiss="modal">Update</button>    
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    </fetcher.Form>
                </div>
                <div className="modal-footer">
                </div>
                </div>
            </div>
        </div>
        </>
    );
}

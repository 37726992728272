import { useLoaderData, redirect, useNavigate, useFetcher } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { getListAgeGroupsAndGenders, postAgeGroups } from '../utils/api';


export default function AgeGroupsPage() {

    const [ageGroups, setAgeGroups] = useState([]);
    const navigate = useNavigate();
    const fetcher = useFetcher();
    const data = useLoaderData();
    const msgRef = useRef(null);

    let count = 0; // this is rendering twice?
    useEffect(() => {
        if (count === 0) {
            setAgeGroups([]);
            if (data.ageGroups.success.length > 0) {
                for (let i = 0; i < data.ageGroups.success.length; i += 2) {
                    setAgeGroups(ageGroups => [...ageGroups, data.ageGroups.success[i]]);
                }
            } else {
                const ag = {
                    age_group_id: '',
                    min_age: '',
                    max_age: '',
                    age_group_name: '',
                    num_places: 5
                };
                setAgeGroups(ageGroups => [...ageGroups, ag]);
            }
            count++;
        }

    }, [data.ageGroups.success, count]);

    const scrollToTop = () => {
        window.scrollTo({
            top: msgRef.current.offsetTop -50,
            behavior: "instant"
        });
    }

    if (fetcher?.data) {
        scrollToTop();
    }

    const resetAgeGroups = () => {
        setAgeGroups([]);
        return navigate(`/admin/agegroups/${data.series_id}/${data.series_name}`);

    } 

    const insertRow = ((index) => {
        const ag = {
            age_group_id: '',
            min_age: '',
            max_age: '',
            age_group_name: '',
            num_places: 5
        };
        let newArr = [...ageGroups];
        newArr.splice(index+1, 0, ag);
        setAgeGroups(newArr);
    });

    const removeRow = ((index) => {
        let newArr = [...ageGroups];
        newArr.splice(index, 1);
        setAgeGroups(newArr);
    });

    // const saveAgeGroups = (() => {
    //     for (let i=0; i<ageGroups.length; i++) {
    //         console.log(ageGroups[i]);
    //     }
    // })

    const updateAgeGroupName = ((e, index) => {
        let newArr = [...ageGroups];
        newArr[index].age_group_name = e;
        setAgeGroups(newArr);
    });

    const updateMinAge = ((e, index) => {
        let newArr = [...ageGroups];
        newArr[index].min_age = e;
        newArr[index].age_group_name = newArr[index].min_age + newArr[index].max_age;
        setAgeGroups(newArr);
    });

    const updateMaxAge = ((e, index) => {
        let newArr = [...ageGroups];
        newArr[index].max_age = e;
        newArr[index].age_group_name = newArr[index].min_age + newArr[index].max_age;
        setAgeGroups(newArr);
    });


    return (
        <>
        <h3 className='text-center mt-3' ref={msgRef}>{data.series_name}</h3>
        <p className='text-center text-muted'>All results must be deleted before making any changes to age groups.</p>
        {fetcher.data ? 
                    <p className={`text-center ${fetcher.data.code === 200 ? `text-success` : `text-danger`}`}>{fetcher.data.status}</p> : ''
        }

        <div className="table-responsive  shadow bg-white p-2">
            <fetcher.Form method='post'>
            <table className="table table-sm table-striped" style={{minWidth: '400px'}}>
                <thead>
                    <tr>
                        <th className="standings-medium-font text-muted text-center" scope="col">Min Age</th>
                        <th className="standings-medium-font text-muted text-center" scope="col">Max Age</th>
                        <th className="standings-medium-font text-muted text-center" scope="col">Age Group Name</th>
                        <th className="standings-medium-font text-muted text-center" scope="col">Number Of Places</th>
                        <th className="standings-medium-font text-muted text-center" scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {ageGroups.map((ag, index) =>
                        <tr key={index}>
                            <td className="standings-medium-font text-center">
                                <input type={"number"} className="form-control" id="minAge" name="minAge" required onChange={e => updateMinAge(e.target.value, index) } value={ag.min_age}></input>
                            </td>
                            <td className="standings-medium-font text-center">
                                <input type={"number"} className="form-control" id="maxAge" name="maxAge" required onChange={e => updateMaxAge(e.target.value, index) }value={ag.max_age}></input>
                            </td>
                            <td className="standings-medium-font text-center">
                                <input type={"text"} className="form-control" readOnly id="ageGroupName" name="ageGroupName" required onChange={e => updateAgeGroupName(e.target.value, index) } value={ag.age_group_name}></input>
                            </td>
                            <td className="standings-medium-font text-center">
                                <input type={"text"} className="form-control" id="numPlaces" name="numPlaces" readOnly required defaultValue={ag.num_places}></input>
                            </td>
                            <td>
                                <button type='button' className='btn btn-hermes mx-2 btn-sm mb-1 mb-lg-0' onClick={() => insertRow(index, ag)} >
                                    Insert
                                </button>
                                <button type='button' className='btn btn-hermes mx-2 btn-sm' onClick={() => removeRow(index, ag)} >
                                    Remove
                                </button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            <button type='submit' className='btn btn-hermes mx-2 btn-sm'>
                Save Changes
            </button>
            <button type='button' className='btn btn-hermes mx-2 btn-sm' onClick={() => resetAgeGroups()} >
                Reset
            </button>
            </fetcher.Form>

        </div>
        </>
    )
}

export async function loader({ params }) {

    const series_id = params.series_id;
    const series_name = params.series_name;

    let authInfo = await JSON.parse(localStorage.getItem('authInfo'));
    const today = new Date();
    if (authInfo === null || authInfo?.expiry < today ) {
        localStorage.setItem('authInfo', null);
        return redirect ('/login');
    }

    try {
        const ageGroups = await getListAgeGroupsAndGenders(series_id);
        return {ageGroups, series_id, series_name};
    } catch (err) {
        if (err.status === 401) {
            localStorage.setItem('authInfo', null);
            return redirect('/login');
        }
        return err;
    }
}

export async function action({request, params}) {

    const series_id = params.series_id || 1;
    let msg = {status: '', code: 0};
    const formData = await request.formData();
    let ageGroupArray = [];
    let minAgeArray = formData.getAll('minAge');
    let maxAgeArray = formData.getAll('maxAge');
    let ageGroupNameArray = formData.getAll('ageGroupName');
    let numPlacesArray = formData.getAll('numPlaces');

    if (minAgeArray.length === 0) {
        ageGroupArray.push({
            min_age: null,
            max_age: null,
            age_group_name: null,
            num_places: null,
            series_id: series_id
        })
    } else {
        for (let i =0; i<minAgeArray.length; i++) {
            ageGroupArray.push({
                min_age: minAgeArray[i],
                max_age: maxAgeArray[i],
                age_group_name: ageGroupNameArray[i],
                num_places: numPlacesArray[i],
                series_id: series_id
            })
        } 
    }


    try {
        const results = await postAgeGroups(ageGroupArray);
        if (results.success.count > 0) {
            return msg = {status: 'Created ' + results.success.count + ' records', code:200};
        } else {
            return msg = {status: results.error, code: 500}
        }
        
    } catch (err) {
        console.log(err);
        msg = {status: err.message, code: err.status};
        return msg;
    }

}


import { redirect } from 'react-router-dom';
import { deleteRaceResults } from '../utils/api';

export default function DeleteRaceResult () {

    return ( <div>Error Happened</div>);
}

export async function action({ params }) {

    const numDeleted = await deleteRaceResults(params.race_id, params.series_id);

    try {
        let msg = {status: '', code: 0}
        if (numDeleted?.err) {
            msg.status = 'Unable to delete results';
            msg.code = 500;            
        }
        return msg.status = numDeleted.success.count;   
    }    
        catch (err) {
        return err;
    }


}

export function loader() {
    redirect ('/');
}
import { useNavigation, useFetcher } from 'react-router-dom';
import ExportCSV from './ExportCsv';
import MsgComponent from '../components/MsgComponent';


export default function ParticipantUpload(series_id) {

    // const data = useActionData();
    const navigation = useNavigation();
    // const msg = useActionData();
    const fetcher = useFetcher();

    let msg = {};
    if (fetcher?.data) {
        msg = fetcher.data;
        for (let i=0; i<document.getElementsByName('fileUploader').length; i++) {
            document.getElementsByName('fileUploader')[i].value = null;
        }

        fetcher.data = null;
    }
    
    return (
    <>
        <div className='mt-3 col-md-6'>
        <div className='d-flex align-items-center mb-2'>{ msg.code >= 400 ? <MsgComponent msg={msg.status} /> :  <span className='d-flex text-success justify-content-center align-items-center fs-3'>{msg.status}</span>}</div>
            <fetcher.Form method="post" encType='multipart/form-data' action={`/admin/participants/${series_id.series_id}`}>
                <div className="mb-3">
                    <input type={"file"} className="form-control upload-csv " id="fileUploader" name="fileUploader"></input>
                </div>
                <button type="submit" disabled={navigation.state === 'submitting'} className="btn btn-primary btn-hermes">Upload Participants</button>
            </fetcher.Form>
            <p className='mt-2' style={{fontSize:'.85rem'}}><span className='fw-bold'>Required Column Names (case-sensitive): First Name, Last Name, City, State, Gender, Registration Date, Date of Birth</span><br/>
            </p>
        </div>
        <div className='d-flex justify-content-md-center mt-3 col-md-6'>
            <ExportCSV series_id={series_id} />
        </div>
    </>
    );
}

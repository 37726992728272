import { Form, useActionData, useLoaderData, useNavigation, redirect, Link } from 'react-router-dom';
import { postNewRace, getRaceTypeList} from '../utils/api';



export default function RacePostPage() {
    
    const data = useActionData();
    const navigation = useNavigation();
    const raceTypes = useLoaderData();

    return (    
        <Form method="post">
            <div className="row g-3 mt-2 justify-content-start">
                <div className='col-md-4'></div>
                <div className='col-md-3 ps-4'><Link className='hermes-link-color' to={"/admin/races"}>Back</Link></div>
                <div className='col-md-4'></div>
            </div>
            <div className="row g-3 mt-2 justify-content-center">
            <h2 className='text-center hermes-header'>New Race</h2>
                <div className="col-md-4"></div>
                <div className="col-md-3">
                {data && data.status && 
                    <div className="mb-3">
                            <input type={"text"} className="form-control text-danger border border-danger" disabled id="msg" name="msg" value={data.message}></input>
                    </div>
                }
                    <div className="mb-3">
                        <label htmlFor="raceName" className="form-label">Race Name <span style={{color: 'red'}}>*</span></label>
                        <input type={"text"} className="form-control" id="raceName"  name="raceName" required></input>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="raceDate" className="form-label">Race Date <span style={{color: 'red'}}>*</span></label>
                        <input type={"date"} className="form-control" id="raceDate" name="raceDate" required ></input>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="raceDistance" className="form-label">Race Distance <span style={{color: 'red'}}>*</span></label>
                        <input type={"number"} step="0.01" className="form-control" id="raceDistance" name="raceDistance" placeholder='Enter in Miles' required ></input>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="participationPoints" className="form-label">Participation Points <span style={{color: 'red'}}>*</span></label>
                        <input type={"text"} className="form-control" id="participationPoints" name="participationPoints" required ></input>
                    </div>
                    <div className="mb-3">
                        <label className="form-label text-secondary" htmlFor="racetype">Race Type <span style={{color: 'red'}}>*</span></label>
                        <select id='raceTypeSelect' className="form-select font-monospace text-secondary" aria-label="Select Race Type" name="raceTypeSelect" required>

                            { raceTypes.raceTypeList.map((rt) => <option key={rt.race_type_id} value={rt.race_type_id}>{rt.race_type_name}</option>)}
                            
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="raceUrl" className="form-label">Race Website URL</label>
                        <input type={"text"} className="form-control" id="raceUrl" name="raceUrl"></input>
                    </div>
                    <button type="submit" disabled={navigation.state === 'submitting'} className="btn btn-primary btn-hermes">Add Race</button>
                </div>
                <div className="col-md-4"></div>
            </div>
        </Form>
    );
}

export async function action ({ request }) {

    const formData = await request.formData();
    const post = {
        race_name: formData.get('raceName'),
        race_date: formData.get('raceDate'),
        race_distance: formData.get('raceDistance'),
        race_url: formData.get('raceUrl'),
        participation_points: formData.get('participationPoints'),
        race_type_id: formData.get('raceTypeSelect')
    }

    try {
        await postNewRace(post);
        return redirect('/admin/races');
        // return newRaceId;
    } catch (err) {
        if (err.status >= 400) {
            return err;
        }
    }
}

export async function loader () {

    try {

        const raceTypeList = await getRaceTypeList();
        return { raceTypeList };
        // return newRaceId;
    } catch (err) {
        console.log("error");
        if (err.status === 401 ) {
            return redirect('/login');
        }
    }
}




export function formatDateString(dateString) {
// expected format YYYY-MM-DD...
    return dateString.split('-')[0] + '-' + dateString.split('-')[1] + '-' + dateString.split('-')[2].slice(0,2)
}

export async function removeItem() {

        return new Promise ( async (resolve, reject) => {
            try {
                const authInfo = localStorage.setItem('authInfo', null);
                resolve(authInfo);

            } catch (err) {
                localStorage.setItem('authInfo', null);
                reject(err);
            }
        });
    
}

export function checkPassword(passwordTxt){
    // 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character
    let valPass = new RegExp ('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
    if (valPass.test(passwordTxt)) {
        return true;
    } else {
        return false;
    }
}

export function formatDistance(distanceMiles){
    let formattedDistance = '';
    switch (distanceMiles) {
        case '3.1':
            formattedDistance = "5K";
            break;
        case '6.2':
            formattedDistance = "10K";
            break;
        case '9.3':
            formattedDistance = "15K";
            break;
        default:
            formattedDistance = distanceMiles + 'M';
    }

    return formattedDistance;
}

export function formatIntervalAsTime(interval) {
    let formattedTime = '';

    if (interval.hours) {
        formattedTime = interval.hours
    } else {
        formattedTime = '00';
    }
    if (interval.minutes) {
        if (interval.minutes < 10) {
            formattedTime = formattedTime + ':0' + interval.minutes;
        } else {
            formattedTime = formattedTime + ':' + interval.minutes;
        }
    } else {
        formattedTime += ':00';
    }
    if (interval.seconds) {
        if (interval.seconds < 10) {
            formattedTime = formattedTime + ':0' + interval.seconds;
        } else {
            formattedTime = formattedTime + ':' + interval.seconds;
        }
    } else {
        formattedTime += ':00';
    }
    return formattedTime;
}
import { useState, useRef } from 'react';
import { useLoaderData, useNavigate, useFetcher, redirect } from 'react-router-dom';
import MsgComponent from '../components/MsgComponent';
// import SearchRaces from "../components/SearchRaces"
import { getAllRacesList } from '../utils/api';
import { formatDateString } from '../utils/helpers';



export default function RacesPage() {
    
    const raceData = useLoaderData();
    const navigate = useNavigate();
    const fetcher = useFetcher();  

    const [confirmDialog, setConfirmDialog] = useState(''); 
    const [raceId, setRaceId] = useState(null);
    // const [msgInfo, setMsgInfo] = useState(null);

    const msgRef = useRef(null);

    const scrollToTop = () => {
        window.scrollTo({
            top: msgRef.current.offsetTop -50,
            behavior: "instant"
        });
    }


    const editRace = (race_id) => {

        return navigate(`/admin/edit-race/${race_id}`);
    }

    const deleteRace = (race_id, race_name) => {

        setConfirmDialog(race_name + '?');
        setRaceId(race_id);

        return race_name;
    }

    const saveDelete = () => {
        scrollToTop();
    }

    const newRace = () => { return navigate(`/admin/new-race`);}
    
    return (
        <>
        <h2 className='text-center hermes-header pt-3' ref={msgRef}>My Races</h2>
        { fetcher.data?.code >= 400 ? <MsgComponent msg={fetcher.data.status} /> : ''}
        {/* <div className='row'>
            <div className='col-xl-3'></div>
            <div className='mt-3 col-md-6 col-xl-6'>
                <SearchRaces series_id={ raceData.series_id } />
            </div>
            <div className='col-md-6 col-xl-3'></div>
        </div> */}
        <div className='row'>
            <div className='col-xl-3'></div>
            <div className='mt-3 col-xl-6'><button type='button' className='btn btn-hermes me-2' onClick={newRace}>New Race</button></div>
            <div className='col-xl-3'></div>
        </div>
        <div className='row'>
            <div className='col-xl-3'></div>
            <div className='mt-3 col-xl-6'> 
            { raceData.map((race) => 
                <div key={race.race_id} className="card mb-3">
                    {/* <img src='2022-RRS_logo-1.jpg' style={{width:'65px'}} className='card-img-top' alt="Hermes Logo"></img> */}
                    <div className="card-body">
                        <h5 className="card-title pb-2">{race.race_name}</h5>
                        <div className="card-subtitle mb-3 text-muted" style={{fontSize: ".90rem"}}>
                            ID: {race.race_id} 
                            <div className='vr mx-2'></div> 
                            Date: {formatDateString(race.race_date)} 
                            <div className='vr mx-2'></div> 
                            Participation Points: {race.participation_points} 
                            <hr/>
                            Race Distance: {race.race_distance + 'M'}
                            <div className='vr mx-2'></div> 
                            Race Type: {race.race_type_name}
                            <hr/>
                            {/* <br className='my-2'/> */}
                            <span>Website: {race.race_url}</span>
                        </div>
                        {/* <button type='button' className='btn btn-hermes me-2 btn-sm'>Edit Series</button> */}
                        <button type='button' className='btn btn-hermes me-2 btn-sm' onClick={() => editRace(race.race_id)}>Edit</button>
                        <button type='button' className='btn btn-hermes me-2 btn-sm' 
                            data-bs-toggle="modal" data-bs-target="#confirmModal" onClick={(event) => deleteRace(race.race_id, race.race_name)} >
                            Delete
                        </button>
                    </div>
                </div>
            )}
            </div>
            <div className='col-xl-3'></div>
        </div>
        <div className="modal" id='confirmModal' tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title text-danger">Confirm Delete</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <p className='fw-bold'>Are you sure you want to delete {confirmDialog}</p>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <fetcher.Form
                         method="post"
                        action={`/admin/delete-race/${raceId}`}
                        onSubmit={((event) =>  saveDelete(event))} 
                        >

                        <button type="submit" className='btn btn-danger' data-bs-dismiss="modal">Delete</button>
                    </fetcher.Form>
                    <button id='hiddenSubmitButton' hidden data-bs-dismiss="modal"></button>
                </div>
                </div>
            </div>
        </div>

        </>
    );
}

export async function loader () {

    let authInfo = await JSON.parse(localStorage.getItem('authInfo'));
    const today = new Date();
    if (authInfo === null || authInfo?.expiry < today ) {
        localStorage.setItem('authInfo', null);
        return redirect ('/login');
    }


    try {
        const raceList = await getAllRacesList();
        return raceList;
    } catch (err) {
        if (err.status === 401 ) {
            localStorage.setItem('authInfo', null);
            return redirect('/login');
        }
        return err; 
    }
}


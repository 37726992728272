import { useState } from 'react';
import { redirect, useLoaderData, useNavigate } from 'react-router-dom';
import ExportSeriesStats from '../components/ExportSeriesStats';
import { getSeriesList, postFinalResults } from '../utils/api';
import { formatDateString } from '../utils/helpers';
import MsgComponent from '../components/MsgComponent';

export default function SeriesPage() {
    
    const {seriesData } = useLoaderData();
    const navigate = useNavigate();
    const [msg, setMsg] = useState({});


    // const seriesDate = new Date(seriesData[0].series_age_date).getFullYear;

    const handleParticipants = (series_id) => {
        // console.log(series_id);
        return navigate(`/admin/participants/${series_id}`);
    }

    const editSeriesInfo = (series_id) => {
        return navigate(`/admin/edit-series/${series_id}`);
    }

    const scoring = (series_id) => {
        return navigate(`/admin/scoring/${series_id}`);
    }

    const ageGroups = (series_id, series_name) => {
        return navigate(`/admin/agegroups/${series_id}/${series_name}`);
    }

    const finalizeAwards = async (series_id) => {
        try {
            let numResults = await postFinalResults(series_id);
            numResults.series_id = series_id;
            setMsg(msg => ({
                ...msg,
                ...numResults
            }));
                
        } catch (err) {
            return err;
        }
    }


    return (
        <>
        <h2 className='text-center hermes-header py-3'>My Series</h2>
        <div className='row'>
            <div className='col-xl-3'></div>
            <div className='mt-3 col-xl-6'> 
            { seriesData.map((series) => 
                <div key={series.series_id} className="card mb-3">
                    {/* <img src='2022-RRS_logo-1.jpg' style={{width:'65px'}} className='card-img-top' alt="Hermes Logo"></img> */}
                    <div className="card-body">
                        <h5 className="card-title">{series.series_name}</h5>

                        <div className="card-subtitle mb-3 text-muted" style={{fontSize: ".90rem"}}>
                                Series ID: {series.series_id}
                            <div className='vr mx-2'></div> 
                            Series Age Date: {formatDateString(series.series_age_date)} 
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                {msg && msg.series_id === series.series_id  ?
                                 msg.error.length > 0 ? <MsgComponent msg={msg.error[0]} /> : <span className='d-flex text-success justify-content-center align-items-center mb-2 fs-3'>Successfully Updated Awards</span>
                                : ""}
                                
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'> 
                                <button type='button' className='btn btn-hermes me-2 btn-sm btn-hermes-series mt-1' onClick={() => editSeriesInfo(series.series_id)}>Edit Series</button>
                                <button type='button' className='btn btn-hermes me-2 btn-sm btn-hermes-series mt-1' onClick={() => ageGroups(series.series_id, series.series_name)}>Age Groups</button>
                                <button type='button' className='btn btn-hermes me-2 btn-sm btn-hermes-series d-none d-md-inline mt-1' onClick={() => handleParticipants(series.series_id)}>Participants</button>
                                <button type='button' className='btn btn-hermes me-2 btn-sm btn-hermes-series d-none d-md-inline mt-1' onClick={() => scoring(series.series_id)}>Scoring</button>
                                <button type='button' className='btn btn-hermes me-2 btn-sm btn-hermes-series d-none d-md-inline mt-1' onClick={() => finalizeAwards(series.series_id)}>Finalize Awards</button>
                                <span className='d-none d-sm-inline'><ExportSeriesStats seriesId = {series.series_id} filename = {series.series_name} /></span>
                            </div>
                            <div className='col-12 pt-2 pt-xl-0 d-md-none'>
                                <button type='button' className='btn btn-hermes me-2 btn-sm btn-hermes-series mt-1' onClick={() => handleParticipants(series.series_id)}>Participants</button>
                                <button type='button' className='btn btn-hermes me-2 btn-sm btn-hermes-series mt-1' onClick={() => scoring(series.series_id)}>Scoring</button>
                                {/* <button type='button' className='btn btn-hermes me-2 btn-sm btn-hermes-series d-none d-xs-inline' onClick={() => finalizeAwards(series.series_id)}>Finalize Awards</button> */}
                            </div>
                            <div className='col-12 pt-2 pt-xl-0 d-block d-sm-none'>
                                <button type='button' className='btn btn-hermes me-2 btn-sm btn-hermes-series mt-1' onClick={() => finalizeAwards(series.series_id)}>Finalize Awards</button>
                                <ExportSeriesStats seriesId = {series.series_id} filename = {series.series_name} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            </div>
            <div className='col-xl-3'></div>
        </div>
        </>
    );
}

export async function loader () {

    let authInfo = await JSON.parse(localStorage.getItem('authInfo'));
    const today = new Date();
    if (authInfo === null || authInfo?.expiry < today ) {
        localStorage.setItem('authInfo', null);
        return redirect ('/login');
    }

    try {
        const seriesData = await getSeriesList();
        return { seriesData };
    } catch (err) {
        if (err.status === 401 ) {
            localStorage.setItem('authInfo', null);
            return redirect('/login');
        }
        return err; 
    }
}

// import { useEffect } from "react";
import { useFetcher, redirect, useLoaderData, useSubmit } from "react-router-dom";
import { getParticipantsByName, getSeriesStandings, getListAgeGroupsAndGenders } from "../utils/api";
import StandingsDisplay from '../components/StandingsDisplay';
export default function SearchByName() {
    
    const fetcherName = useFetcher();
    const data = useLoaderData();
    const submit = useSubmit();

    // useEffect(() => {
    //     document.getElementById("categorySelect").value = categoryList.props.categorySelect;
    // },[categoryList.props.categorySelect]);

    return (
    <>
    <div className="row mt-5"> 
        <div className="col-xl-3"></div>
        <div className="col-12 col-xl-3">
            <fetcherName.Form action={`/search-name/${data.seriesId}`} >
                <div className="row">
                        <div className="col">
                        <input type={"text"} 
                            className="form-control" 
                            id="fullName" name="fullName" 
                            placeholder="Search By Name"
                            aria-label="Search By Name" 
                             >
                        </input>
                        {/* <input type={"text"} hidden id="seriesId" name="seriesId" value={props.props.rankings[0].series_id} readOnly /> */}
                        </div>
                        <div className="col">
                            <button className='btn btn-secondary' role="search">Search</button>
                        </div>
                    </div>
                    <label className="form-label text-secondary" htmlFor="category">Filter By:</label>
                    <select id='categorySelect' className="form-select text-secondary" aria-label="Select Category" name="categorySelect" onChange={e => submit(e.currentTarget.form)}>
                        <optgroup label="Overall">
                            <option className="text-secondary" defaultValue={'Overall'}>Overall</option>
                        </optgroup>
                        <optgroup className="text-secondary" label={'Gender:'}>
                            <option defaultValue={'Female'}>Female</option>
                            <option defaultValue={'Male'}>Male</option>
                        </optgroup>
                        <optgroup label={'Age Group:'}>
                            { data.categoryList.success.map((cat, index) => <option key={index} value={cat.gender + '-' + cat.age_group_id}>{cat.gender + '-' + cat.age_group_name}</option>)}
                        </optgroup>
                    </select>
            </fetcherName.Form>
        </div>
        <div className="col-xl-3"></div>
        <div className="col-xl-3"></div>
        {/* <SearchStandings props={data} /> */}
        {fetcherName.data ? <StandingsDisplay props={fetcherName.data.results.success}/>  : <StandingsDisplay props={data.standings.success}/> }
        
    </div>
    </>
    )

}

export async function loader({ request, params }) {

    const url = new URL(request.url);
    const seriesId = params.series_id || 1;
    const fullName = url.searchParams.get("fullName"); 
    const categorySelect = url.searchParams.get("categorySelect") || 'Overall'; 
    const standings = await getSeriesStandings(seriesId, categorySelect);
    const categoryList = await getListAgeGroupsAndGenders(seriesId);
    // const seriesId = url.searchParams.get("seriesId");
    
    // console.log(url.pathname);
    try {
        const results = await getParticipantsByName(seriesId, fullName);
        return { results, seriesId, standings, categoryList};
        // return newRaceId;
    } catch (err) {
        console.log("error");
        if (err.status === 401 ) {
            return redirect('/login');
        }
        // return err; 
    }

}

import { useEffect, useRef, useState } from "react";
import { CSVLink } from 'react-csv';
import { formatIntervalAsTime } from "../utils/helpers";
import { getSeriesStats } from "../utils/api";




export default function ExportSeriesStats({seriesId, filename}) { 

    const csvLinkRef = useRef(null);


      const [fileHeaders] = useState([
        { label: "SeriesId", key: "participant_series_id"},
        { label: "First Name", key: "first_name" },
        { label: "Last Name", key: "last_name" },
        { label: "DOB", key: "dob" },
        { label: "Gender", key: "gender"},
        { label: "Number Races", key: "num_races"},
        { label: "Avg Chip Time", key: "formatted_avg_chip"},
        { label: "Avg Pace", key: "formatted_avg_pace"},
        { label: "Avg Age Grade %", key: "avg_age_grade"},
        { label: "Standard Deviation Pace", key: "stdev_pace"}
      ]);

      const [fileName, setFileName] = useState('');
      const [fileData, setFileData] = useState([]);


    const handleDataFetch = async() => {

        const seriesStats = await getSeriesStats(seriesId);
        for (let i = 0; i < seriesStats.length; i++) {
            seriesStats[i].formatted_avg_chip = formatIntervalAsTime(seriesStats[i].avg_chip_time);
            seriesStats[i].formatted_avg_pace = formatIntervalAsTime(seriesStats[i].avg_pace);
          }
          setFileName(filename.split(' ')[0] + filename.split(' ')[1] + 'Stats.csv');
          setFileData(seriesStats);
    };

    useEffect(() => {
        if (fileData && fileName) {
            csvLinkRef.current.link.click();
        }
    }, [fileName, fileData]);


    return (
        <>

                <CSVLink
                    headers={fileHeaders}
                    data = {fileData}
                    filename = {fileName} 
                    ref={csvLinkRef} 
                    hidden>
                        Export to CSV
                </CSVLink>
                <button type="button" onClick={handleDataFetch} className="btn btn-hermes me-2 btn-sm d-md-inline mt-1">Download Stats</button>

        </>
    )

}
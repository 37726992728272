import { useLoaderData, redirect, Link } from 'react-router-dom'
import SearchRaces from "../components/SearchRaces"
import SeriesRaces from '../components/SeriesRaces';
import { getSeriesRaceList, getSeriesInfo } from '../utils/api';

export default function ScoringPage() {
    
    const raceData = useLoaderData();

    return (
        <>
        <div className="container-fluid mb-2">
        <div className='col col-xl-3 mt-4 mx-auto'><Link className='hermes-link-color' to={"/admin/series"}>Back</Link></div>
            <h2 className='text-center hermes-header py-3 fw-bold'>{raceData.seriesName[0].series_name}</h2>
            <div className='row'>
                <div className='col-12 col-lg-3'></div>
                <div className='col-12 col-md-6 col-lg-6'>
                    <SearchRaces series_id={ raceData.series_id } />
                </div>
                <div className='col-12 col-md-6 col-lg-3'></div>
            </div>
            <SeriesRaces raceData={ raceData.raceData } />
        </div>
        </>
    )

}

export async function loader({params}) {
    const series_id = params.series_id;
    try {
        const raceList = await getSeriesRaceList(series_id);
        const seriesName = await getSeriesInfo(series_id);
        // console.log(raceList);
        const raceData = {
            series_id: series_id,
            raceData: raceList,
            seriesName: seriesName
        }
        return raceData;
    } catch (err) {
        if (err.status === 401 ) {
            return redirect('/login');
        }
        return err; 
    }
}
import { redirect } from 'react-router-dom';
import { deleteRaceFromSeries } from '../utils/api';

export default function DeleteRaceFromPage () {

    return ( <div>Error Happened</div>);
}

export async function action({ params }) {

    const numDeleted = await deleteRaceFromSeries(params.race_id, params.series_id);
    // console.log(numDeleted.err);
    // throw new Error('My test error');
    // return redirect('/races');
    try {
        let msg = {status: '', code: 0}
        if (numDeleted?.code) {
            if (numDeleted.code === 23503) {
                msg.code = 23503;
                msg.status = 'You must delete all results before deleting a race.';
            } else {
                msg.status = 'Unable to delete race';
                msg.code = 500;
            }
        }
        return msg;        
    } catch (err) {
        return err;
    }


}

export function loader() {
    redirect ('/series');
}
import { Form, useNavigation, redirect, useActionData } from 'react-router-dom';
import MsgComponent from '../components/MsgComponent';
import { newUser } from '../utils/api';

export default function NewUser() {

    const navigation = useNavigation();
    const actionData = useActionData();

    return ( 
        <div className="container-fluid mb-4">
            <Form method="post" autoComplete='off'>
                <div className="row d-flex align-items-center mb-3">
                    <div className="col-12 col-lg-3 col-xl-4"></div>
                    <div className="col-12 col-lg-6 col-xl-4">
                        <div className="mb-3">
                            <h2 className="text-center hermes-header pt-3" >New User</h2>
                            { actionData?.code >= 400 ? <MsgComponent msg={actionData.status} /> : ''}
                        </div>
                        <div className="mb-3">
                            
                        <label htmlFor="username" id="lblEmailAddress" className="form-label">Email address <span style={{color: 'red'}}>*</span></label>
                        <input type="email" autoComplete='none' className="form-control" id="username" aria-describedby="lblEmailAddress" name="username" required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="firstname" id="lblFirstName" className="form-label">First Name <span style={{color: 'red'}}>*</span></label>
                            <input type="text" className="form-control" id="firstname" aria-describedby="lblFirstName" name="firstname" required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="lastname" id="lblLastName" className="form-label">Last Name <span style={{color: 'red'}}>*</span></label>
                            <input type="text" className="form-control" id="lastname" aria-describedby="lblLastName" name="lastname" required />
                        </div>
                        <div className="mb-3">
                        <label htmlFor="password" id="lblPassword" className="form-label">Password <span style={{color: 'red'}}>*</span> <span style={{fontSize: ".85rem"}}>(min 8 characters with one lowercase letter, one uppercase letter, one numeric digit, and one special character)</span></label>
                        <input type="password" autoComplete='new-password' aria-describedby="lblPassword" className="form-control" id="password" name="password" minLength="8" required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="confirmPassword" id="lblConfirmPassword" className="form-label">Confirm Password <span style={{color: 'red'}}>*</span></label>
                            <input type="password" aria-describedby="lblConfirmPassword" className="form-control" id="confirmPassword" name="confirmPassword" required minLength="8"/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="isAdmin" id="isAdmin" className="form-label">Admin </label>
                            <input type="checkbox" aria-describedby="isAdmin" className="form-check-input ms-2" id="isAdmin" name="isAdmin" />
                        </div>
                        <button type="submit" disabled={navigation.state === 'submitting'}  className="btn btn-primary btn-hermes">Submit</button><br/>
                        
                    </div>
                    <div className="col-12 col-lg-3 col-xl-4"></div>
                </div>
        </Form>
    </div>
    )

}

export async function loader() {


    let authInfo = await JSON.parse(localStorage.getItem('authInfo'));
    const today = new Date();
    if (authInfo === null || authInfo?.expiry < today ) {
        localStorage.setItem('authInfo', null);
        return redirect ('/login');
    } 
}

export async function action ({request}) {

    const formData = await request.formData();

    let msg = {
        status: '',
        code: 0
    };

    const userEmail = formData.get('username');
    const userFirstName = formData.get('firstname');
    const userLastName = formData.get('lastname');
    const userPassword = formData.get('password');
    const confirmPassword = formData.get('confirmPassword');
    const isAdmin = formData.get('isAdmin');

    let admin = false;

    if (userPassword !== confirmPassword) {
        msg.status = 'Passwords do not match';
        msg.code = 600
        return msg;
    }

    if (!checkPassword(userPassword)) {
        msg.status = 'Password must be 8 characters long, include 1 lowercase and 1 uppercase letter, 1 number and 1 special character';
        msg.code = 600;
        return msg;
    }

    if (isAdmin) {
        admin = true;
    }

    const data = {
        user_email: userEmail,
        user_first_name: userFirstName,
        user_last_name: userLastName,
        user_password: userPassword,
        is_admin: admin
    }


    try {
        await newUser(data);
        return redirect('/admin/manage-user');
        // return newRaceId;
    } catch (err) {

        if (err.status === 401 ) {
            return redirect('/login');
        }
        msg.status = err.status.error;
        msg.code = err.code;
        return msg;
    
    }
}

const checkPassword = (passwordTxt) => {
    // 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character
    let valPass = new RegExp ('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
    if (valPass.test(passwordTxt)) {
        return true;
    } else {
        return false;
    }
}

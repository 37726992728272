
// import {  useEffect } from 'react';
import {Outlet, redirect, useLoaderData, Form, NavLink, useNavigate, useLocation, useNavigation, Link } from 'react-router-dom';
import { logoutUser } from '../utils/api';

// import { UserContext } from '../App';


export default function RootLayout() {
    
    let authInfo = useLoaderData();
    const navigate = useNavigate();
    const location = useLocation();
    let mySeriesActive = false;
    let myRacesActive = false;
    let homeActive = false;
    const navigation = useNavigation();

    const setActiveLink = () => {
    
         if (location.pathname.includes('series')) {
            mySeriesActive = true;
            myRacesActive= false;
            homeActive = false;
            // userActive = false;
        } else if (location.pathname.includes('races')) {
            mySeriesActive = false;
            myRacesActive = true;
            homeActive = false;
            // userActive = false;
        } else if (location.pathname === '/') {
            mySeriesActive = false;
            myRacesActive = false;
            homeActive = true;
            // userActive = false;
        } else if (location.pathname.includes('user')) {
            mySeriesActive = false;
            myRacesActive = false;
            homeActive = false;
            // userActive = true;
        }

    }

    setActiveLink();


    // current workaround to make off canvas close on link click
    function goToSeries() {
        navigate('/admin/series');
    }

    const goToHome = () => {
        navigate('/');
    }

    const goToRaces = () => {
        navigate('/admin/races');
    }

    const goToNewUser = () => {
        navigate('/admin/new-user');
    }

    const goToManageUsers = () => {
        navigate('/admin/manage-user');
    }

    // const goToRaces = () => {
    //     navigate('/admin/races');
    // }

    
    return (
        <>
        <nav className="navbar navbar-expand-xl nav-bar-header sticky-top" id='myNavbar'>
            <div className="container-fluid">
                { authInfo ? 
                    <><button className="navbar-toggler bg-light" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                            <span className="navbar-toggler-icon"></span>
                    </button>
                        <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                                <div className="offcanvas-header" style={{backgroundColor:'lightgray'}}>
                                    <h5 className="offcanvas-title fw-2" id="offcanvasNavbarLabel" style={{color: "rgba(60,106,176,0.9)"}}>Admin Dashboard</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                </div>
                                <div className="offcanvas-body">
                                    <ul className="navbar-nav justify-content-start flex-grow-1 pe-3">
                                        <li className="nav-item">
                                            <NavLink className="nav-link" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasNavbar" onClick={goToHome}>
                                                <span className={ homeActive ? 'nav-link text-center hermes-nav-link-active' : 'nav-link text-center hermes-nav-link' }>Home</span>
                                            </NavLink>
                                        </li>
                                        <hr/>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasNavbar" onClick={goToSeries}>
                                                <span className= { mySeriesActive ? 'nav-link text-center hermes-nav-link-active' : 'nav-link text-center hermes-nav-link' }>My Series</span>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasNavbar" onClick={goToRaces}>
                                                <span className= { myRacesActive ? 'nav-link text-center hermes-nav-link-active' : 'nav-link text-center hermes-nav-link' }>My Races</span>
                                            </NavLink>
                                        </li>
                                        {authInfo?.isAdmin ? 
                                            <li className="d-xl-flex text-center align-items-center justify-content-center nav-item dropdown">
                                                <Link className={`nav-link dropdown-toggle text-center hermes-nav-link`}  href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Users
                                                </Link>
                                                <ul className="dropdown-menu">
                                                    <li><Link className="dropdown-item" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasNavbar" onClick={goToNewUser}>New User</Link></li>
                                                    <li><Link className="dropdown-item" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasNavbar" onClick={goToManageUsers}>Manage Users</Link></li>
                                                </ul>
                                            </li> : ''
                                        }

                                    </ul>

                                </div>
                            </div>
                            <Form method='post'>
                                <div className="d-flex">
                                    <button className="btn btn-outline-light" type="submit">{authInfo ? 'Logout' : 'Admin'}</button>
                                </div>
                            </Form></> :
                            <>
                            <a className="navbar-brand nav-bar-brand" href="/">Series Results</a>
                            <Form method='post'>
                            <div className="d-flex">
                                <button className="btn btn-outline-light" type="submit">{authInfo ? 'Logout' : 'Admin'}</button>
                            </div>
                        </Form></>
            }

            </div>
        </nav>
        <main className={`container-fluid ${navigation.state === "submitting" ? "loading" : ""}`}>
                <Outlet />
        </main>
        {/* <ScrollRestoration 
        getKey={(location) => {
            return location.key;
        }}
        /> */}
        </>
    );
}

export async function loader() {

    let authInfo = await JSON.parse(localStorage.getItem('authInfo'));
    if (authInfo?.expiry) {
        const today = new Date();
        const expired = new Date(authInfo.expiry);
        if (expired < today) {
            await removeItem(authInfo);
            authInfo = null;
        }
    }
    return authInfo;
}

async function removeItem(authInfo) {


    return new Promise ( async (resolve, reject) => {
        try {
            const response = await logoutUser();
            if (response.status === 200) {
                localStorage.setItem('authInfo', null);
                authInfo = null;
            }
        } catch (err) {
            localStorage.setItem('authInfo', null);
            reject(err);
        }
        resolve(authInfo);
    });

}

export async function action () {

    let authInfo = JSON.parse(localStorage.getItem('authInfo'));

    try {
        if (authInfo) {
            await removeItem(authInfo);
            return redirect(`/`);
        } else {
            return redirect('/login');
        }
        
    } catch (err) {
        return err;
    }
}


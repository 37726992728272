import { RouterProvider, createBrowserRouter, createRoutesFromElements, Route} from 'react-router-dom';
// import logo from './logo.svg';
import './App.css';

import RootLayout, {loader as rootLoader, action as loginButtonAction } from './Pages/RootLayout';
import WelcomePage, {loader as welcomeLoader } from './Pages/Welcome';
import LoginPage, { action as loginUserAction, loader as loginLoader} from './Pages/Login';
import StandingsPage, { loader as standingsLoader } from './Pages/Standings';
import StandingDetailPage, { loader as standingsDetailsLoader } from './Pages/StandingDetailPage';
import SeriesPage, { loader as seriesListLoader } from './Pages/Series';
import EditSeriesPage, { loader as editSeriesLoader, action as editSeriesAction } from './Pages/EditSeries';
import ErrorPage  from './Pages/Error'
import ParticipantPage, { loader as participantDataLoader, action as fileUploadAction} from './Pages/Participants';
import UpdateParticipant, {loader as updateParticipantLoader, action as updateParticipantAction} from './components/UpdateParticipant';
import RacePostPage, { action as newRaceAction, loader as newRaceLoader} from './Pages/RacePost';
import RacesPage, { loader as racesLoader } from './Pages/Races';
import EditRacePage, { loader as editRaceLoader, action as editRaceAction} from './Pages/EditRace';
import DeleteRacePage, { action as deleteRaceAction } from './components/DeleteRace';
import ScoringPage, { loader as scoringPageLoader } from './Pages/Scoring';
import SearchRaces, {loader as searchRacesLoader } from './components/SearchRaces';
import DeleteRaceFromPage, {loader as deleteSerieRaceLoader, action as deleteSeriesRaceAction}  from './components/DeleteSeriesRace';
import PostResultsPage, { loader as postResultsLoader, action as postResultsAction } from './components/PostResults';
import DeleteRaceResult, { action as deleteRaceResultsAction} from './components/DeleteRaceResult';
import SearchByName, {loader as searchByNameLoader} from './components/SearchByName';
import ProtectedRoutes, {loader as ProtectedRoutesLoader } from './components/ProtectedRoutes';
import AgeGroupsPage, {loader as ageGroupsLoader, action as ageGroupsAction } from './Pages/AgeGroups';
import NewUser, {loader as newUserLoader, action as newUserAction } from './Pages/NewUser';
import ManageUsers, {loader as manageUserLoader, action as updateUserAction} from './Pages/ManageUsers';
// import ProfilePage from './Pages/ProfilePage';
// import AdminNavigation from './components/AdminNavigation';

const router = createBrowserRouter(createRoutesFromElements( 
<>
<Route path='/login' element={<LoginPage />} loader={loginLoader} action={loginUserAction} />
<Route path="/" element={<RootLayout />} loader={rootLoader} action={loginButtonAction} errorElement={<ErrorPage />}>
  <Route index element={<WelcomePage />} loader={welcomeLoader} />
  <Route path='/standings/:series_id' element={<StandingsPage />} loader={standingsLoader}/>
  <Route path='/standing-detail/:series_id/:participant_series_id' element={<StandingDetailPage />} loader={standingsDetailsLoader} />
  <Route path='/search-name/:series_id' element={<SearchByName />} loader={searchByNameLoader} />

  
  <Route path='/admin' element={<ProtectedRoutes />} loader={ProtectedRoutesLoader}>
    <Route index element={<SeriesPage />} loader={seriesListLoader} />
    <Route path="series" element={<SeriesPage />} loader={seriesListLoader} />
    <Route path="edit-series/:series_id" element={<EditSeriesPage />} loader={editSeriesLoader} action={editSeriesAction} />
    <Route path="agegroups/:series_id/:series_name" element={<AgeGroupsPage />} loader={ageGroupsLoader} action={ageGroupsAction} />

    <Route path='races' element={<RacesPage />} loader={racesLoader} />
    <Route path='new-race' element={<RacePostPage />} action={newRaceAction} loader={newRaceLoader} />
    <Route path='edit-race/:race_id' element={<EditRacePage />} action={editRaceAction} loader={editRaceLoader} />
    <Route path='delete-race/:race_id' element={<DeleteRacePage />}  action={deleteRaceAction} errorElement={<ErrorPage />} />

    <Route path='participants/:series_id' element={<ParticipantPage />} action={fileUploadAction} loader={participantDataLoader}/>
    <Route path='update-participant' element={<UpdateParticipant />} action={updateParticipantAction} loader={updateParticipantLoader} />
    
    <Route path='scoring/:series_id' element={<ScoringPage />} loader={scoringPageLoader} />
    <Route path='scoring-series-list' element={<SearchRaces />} loader={searchRacesLoader} />
    <Route path='delete-seriesrace/:race_id/:series_id' element={<DeleteRaceFromPage />} loader={deleteSerieRaceLoader} action={deleteSeriesRaceAction} />

    <Route path='results/postresults/:race_id/:series_id' element={<PostResultsPage/>} loader={postResultsLoader} action={postResultsAction} />
    <Route path='delete-race-results/:race_id/:series_id' element={<DeleteRaceResult />} action={deleteRaceResultsAction} />

    <Route path='new-user' element={<NewUser />} loader={newUserLoader} action={newUserAction} />
    <Route path='manage-user' element={<ManageUsers />} loader={manageUserLoader} action={updateUserAction}  />
  </Route>
</Route>
</>))

function App() {

  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
      </header>
        <RouterProvider router={router} />
    </div>
  );
}

export default App;

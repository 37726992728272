import { useState, useEffect } from 'react';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Legend, Tooltip } from 'chart.js';
import { Line } from 'react-chartjs-2';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Legend, Tooltip);

export default function AvgLineChart(detailedResults) {


    const timeToSeconds = (data) => {
        const timeArray = data.split(':');
        if (timeArray.length === 2) {
            return (parseInt(timeArray[0]) * 60) + parseInt(timeArray[1]);
        }
        if (timeArray.length === 3) {
            return (parseInt(timeArray[0]) * 60 * 60) + (parseInt(timeArray[1]) * 60) + parseInt(timeArray[2]);
        }
    }

    const filterRaces = (gender) => {
        let mergedData = [];
        for (let i = 0; i < detailedResults.props[0].length; i++) {
            for (let x = 0; x < detailedResults.props[1].length; x++) {
                if ((detailedResults.props[0][i].race_id === detailedResults.props[1][x].race_id) && detailedResults.props[1][x].gender === gender) {
                    let time = 0;
                    if (detailedResults.props[1][x].avg.hours) {
                        time = parseInt(detailedResults.props[1][x].avg.hours) * 60 * 60;
                    }
                    if (detailedResults.props[1][x].avg.minutes) {
                        time = time + (parseInt(detailedResults.props[1][x].avg.minutes) * 60);
                    }
                    if (detailedResults.props[1][x].avg.seconds) {
                        time = time + parseInt(detailedResults.props[1][x].avg.seconds);
                    }
                    mergedData.push(time);
                }
            }
        }
        return mergedData;
    }


    const [chartData] = useState({
        labels: detailedResults.props[0].map((data) => data.race_name),
        datasets: [
            {
                label: "My Time",
                data: detailedResults.props[0].map((data) => {
                    const timeSeconds = timeToSeconds(data.chip_time);
                    return timeSeconds;
                }),
                borderColor: 'grey',
                backgroundColor: 'grey'
            },
            {
                label: "Female Average",
                data: filterRaces('F'),
                borderColor: 'red',
                backgroundColor: 'red'
            },
            {
                label: "Male Average",
                data: filterRaces('M'),
                borderColor: 'blue',
                backgroundColor: 'blue'
            }
        ]
    });

    useEffect(() => {
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        const chartAreaWrapper = document.querySelector('.chartAreaWrapper');
        const totalLabels = detailedResults.props[0].length;
        if (detailedResults.props[0].length > 7 && vw >= 1200) {
            const newWidth = 1000 + ((totalLabels - 7) * 50);
            chartAreaWrapper.style.width = `${newWidth}px`;
        } else if (detailedResults.props[0].length > 7 && vw >= 992) {
            const newWidth = 900 + ((totalLabels - 7) * 50);
            chartAreaWrapper.style.width = `${newWidth}px`;
        } else if (detailedResults.props[0].length > 7 && vw >= 768) {
            const newWidth = 850 + ((totalLabels - 7) * 50);
            chartAreaWrapper.style.width = `${newWidth}px`;
        } else if (detailedResults.props[0].length > 7 && vw >= 600) {
            const newWidth = 600 + ((totalLabels - 7) * 50);
            chartAreaWrapper.style.width = `${newWidth}px`;
        } else if (detailedResults.props[0].length > 7) {
            const newWidth = 300 + ((totalLabels - 7) * 50);
            chartAreaWrapper.style.width = `${newWidth}px`;
        } 
    }, [detailedResults.props])

    return (
        <div className='chartWrapper'>
            <h2 className='text-center'>Finish Time</h2>
            <div className='chartAreaWrapper'>
                <Line
                    data={chartData}
                    options={{
                        interaction: {
                            mode: 'index',
                            intersect: false
                        },
                        maintainAspectRatio: false,
                        stacked: false,
                        plugins: {
                            title: {
                                display: true,
                                text: 'Average Time'
                            },
                            legend: {
                                display: true,
                                position: 'bottom',
                                align: 'center',
                                labels: {
                                    boxWidth: 15,
                                    font: {
                                        size: 11
                                    },
                                    padding: 12
                                }
                            },
                            tooltip: {
                                callbacks: {
                                    label: function (context) {
                                        let h = Math.floor(parseInt(context.formattedValue.split(",")[0] + context.formattedValue.split(",")[1]) / 3600);
                                        let m = Math.floor(parseInt(context.formattedValue.split(",")[0] + context.formattedValue.split(",")[1]) % 3600 / 60);
                                        let s = Math.floor(parseInt(context.formattedValue.split(",")[0] + context.formattedValue.split(",")[1]) % 3600 % 60);
                                        if (s < 10) {
                                            s = '0' + s;
                                        }
                                        if (m < 10) {
                                            m = '0' + m;
                                        }
                                        if (h === 0) {
                                            return m + ':' + s;
                                        } else {
                                            return h + ':' + m + ':' + s;
                                        }
                                    }
                                }
                            }

                        },
                        scales: {
                            y: {
                                ticks: {
                                    callback: (v) => {
                                        const convertTime = new Date(v * 1000).toISOString().slice(11, 19);
                                        const timeArray = convertTime.split(':');
                                        if (timeArray[0] === '00' ) {
                                            return timeArray[1] + ':' + timeArray[2];
                                        }
                                        if (parseInt(timeArray[0]) < 10 ) {
                                            return timeArray[0].charAt(1) + ':' + timeArray[1] + ':' + timeArray[2];
                                        }
                                        return convertTime;
                                    },
                                },
                                beginAtZero: true
                            }
                        }
                    }}
                />
            </div>
        </div>
    )
}
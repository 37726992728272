
import { useLoaderData, redirect, useFetcher, useSubmit, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { getListAgeGroupsAndGenders, getSeriesStandings, getSeriesCatName, getSeriesYearsByCatId, getSeriesInfoResults } from '../utils/api';
// import SearchStandings from "../components/SearchStandings";
import StandingsDisplay from '../components/StandingsDisplay';
// import SearchByName from '../components/SearchByName';
// import { getParticipantsByName } from '../utils/api';

export default function StandingsPage() {
    

    const fetcherName = useFetcher();
    const data = useLoaderData();
    const submit = useSubmit();
    const navigate = useNavigate();

    // const submit = useSubmit()


    useEffect(() => {
        document.getElementById("categorySelect").value = data.categorySelect;
    },[data.categorySelect]);

    useEffect(() => {
        document.getElementById("fullName").value = data.fullName;
    },[data.fullName]);

    const handleUpdateYear = (series_id) => {
        return navigate(`/standings/${series_id}?`);
        
    }
 
    return (
        <>
        <div className='container-fluid mt-4'>
            <div className='row mt-3'>
                <div className='col-xs-3 col-xl-3'></div>
                <div className='d-flex justify-content-center align-items-center col-xs-3 col-xl-6'>
                {data.seriesCatName.series_name.includes("Road") ? 
                    <img src='/RRSHermesLogo.png' className='img-fluid hermes-logo-img' alt='Road Race Series Logo'></img>
                    : <img src='/fullmoon.png' className='img-fluid hermes-logo-img' alt='Full Moon Trail Series Logo'></img>
                }
                </div>
                <div className='col'></div>
            </div>


            <div className='row mt-2 mb-5'>
                <div className="col-12 col-xl-3"></div>
                <div className='col-12 col-xl-3'>
                    <fetcherName.Form  >
                        <div className='row mt-4'>
                            <div className='col'>
                                <label className="form-label text-secondary" htmlFor="seriesYearSelect">Select Year: <span style={{color: 'red'}}>*</span></label>
                                <select id='seriesYearSelect' value={data.seriesId} onChange={e => handleUpdateYear(e.currentTarget.value)} className="form-select font-monospace text-secondary" aria-label="Select Year" name="seriesYearSelect" required>
                                    { data.seriesYears.map((sy) => <option key={sy.series_year} value={sy.series_id}>{sy.series_year}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div>
                                <label className="form-label text-secondary" htmlFor="categorySelect">Filter By:</label>
                                <select id='categorySelect' className="form-select font-monospace text-secondary" aria-label="Select Category" name="categorySelect" onChange={e => submit(e.currentTarget.form)}>
                                    <optgroup label="Overall">
                                        <option className="text-secondary" defaultValue={'Overall'}>Overall</option>
                                    </optgroup>
                                    <optgroup className="text-secondary" label={'Gender:'}>
                                        <option defaultValue={'Female'}>Female</option>
                                        <option defaultValue={'Male'}>Male</option>
                                    </optgroup>
                                    {data.seriesCatName.show_age_groups ? 
                                        <optgroup label={'Age Group:'}>
                                                { data.categoryList.success.map((cat, index) => <option key={index} value={cat.gender + '-' + cat.age_group_id}>{cat.gender + ': ' + cat.age_group_name.slice(0, 2) + ' - ' + cat.age_group_name.slice(2,4)}</option>)}
                                        </optgroup> : ""
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="row mt-4">
                        <label className="form-label text-secondary" htmlFor="fullName">Search By Name:</label>
                                <div className="col">
                                <input type={"search"} 
                                    className="form-control" 
                                    id="fullName" name="fullName" 
                                    placeholder="Search By Name"
                                    aria-label="Search By Name"
                                    onChange={(e) => {
                                        const isFirstSearch = data.fullName ==null;
                                        submit(e.currentTarget.form, {
                                            replace: !isFirstSearch,
                                        })
                                    }} 
                                    >
                                </input>
                                </div>
                            </div>
                    </fetcherName.Form>
                </div>

            </div>
            <div className='row'>
            <div>
                <StandingsDisplay props={data.standings.success} seriesYear={data.seriesYear} showAgeGroups={data.seriesCatName.show_age_groups} seriesInfo={data.seriesInfo}/> 
            </div>
            </div>

        </div>
        </>
    );
}

export async function loader({ request, params }) {

    const url = new URL(request.url);
    const seriesId = params.series_id;
    let fullName = url.searchParams.get("fullName"); 
    let categorySelect = url.searchParams.get("categorySelect") || 'Overall'; 
    // let seriesYear = url.searchParams.get("seriesYearSelect");
    // const seriesId = url.searchParams.get("seriesId");
    // console.log(url.pathname)

    try {

        const categoryList = await getListAgeGroupsAndGenders(seriesId);
        // const results = await getParticipantsByName(seriesId, fullName);
        if (fullName === null) {
            fullName = '';
            categorySelect = 'Overall';

        }
        const seriesCatName = await getSeriesCatName(seriesId);
        const seriesYears = await getSeriesYearsByCatId(seriesCatName.series_category_id);
        const seriesInfo = await getSeriesInfoResults(seriesId);
        const standings = await getSeriesStandings(seriesId, categorySelect, fullName, seriesCatName.series_category_id);

        return { seriesId, standings, categoryList, categorySelect, fullName, seriesCatName, seriesYears, seriesInfo};
        // return newRaceId;
    } catch (err) {
        console.log("error");
        if (err.status === 401 ) {
            return redirect('/login');
        }
    }
}

import { useLoaderData, redirect, Link } from 'react-router-dom';
import Papa from 'papaparse';


import ParticipantUpload from '../components/ParticipantUpload';
import ParticipantList from '../components/ParticipantList';
import { getParticipantList, postParticipants } from '../utils/api';
import { formatDateString } from '../utils/helpers';



export default function ParticipantPage() {
    
    const participantData = useLoaderData();

    
    return (
        <div className='container mt-4'>
            <Link className='hermes-link-color' to={"/admin/series"}>Back</Link>
            <h2 className='text-center hermes-header py-3'>Participant Management</h2>
            <div className='row mb-4'>
                <ParticipantUpload series_id={participantData.series_id} props={participantData.participantList}/>
            </div>
            <hr className='mt-5'></hr>
            <div className='row'>
                <ParticipantList props={participantData.participantList} />
            </div>  
        </div>        
    );      
}

export async function action ({ request, params }) {

    const series_id = params.series_id || 1;
    let msg = {status: '', code: 0};
    const formData = await request.formData();

    try {

        const results = await parse(formData.get('fileUploader'), series_id);
        if (results.success > 0) {
            return msg = {status: 'Updated ' + results.success + ' records', code:200};
        }
        // return redirect(`/participants/${series_id}`);
        
    } catch (err) {
        msg = {status: err.message, code: err.status};
        return msg;
    }

}

export async function loader ({params}) {
    const series_id = params.series_id || 1;
    
    try {
        const participantList = await getParticipantList(series_id);

        for (let i=0; i<participantList.length; i++) {
            participantList[i].DOB = formatDateString(participantList[i].DOB);
            participantList[i].registration_date = formatDateString(participantList[i].registration_date);
        }
    
        return {participantList: participantList, series_id: series_id};
    } catch (err) {
        if (err.status === 401 ) {
            return redirect('/login');
        }
        return err; 
    }
}

async function parse(formData, series_id) {

    return new Promise((resolve, reject) => {
        Papa.parse(formData, {
            header: true,
            skipEmptyLines: true,
            complete: async function(results) {
                try {
                    results.series_id = series_id;
                    let res = await postParticipants(results);
                    return resolve(res);
                } catch (err) {
                    return reject(err);
                }
            }
        })
    
    })

}
import {  Outlet, redirect } from "react-router-dom";
// import { useEffect } from "react";
import { removeItem } from "../utils/helpers";

export default function ProtectedRoutes() {

    // const user = true;
    // const navigate = useNavigate();

    // useEffect(() => {
    //     if (!user) {
    //         return navigate('/login');
    //     } 
    // },[user, navigate]);

    return (
        // <div> 
        //     { user ?  <Outlet /> : '' }
        // </div>
        <div> <Outlet /> </div>

    )

}

export async function loader() {

    let authInfo = await JSON.parse(localStorage.getItem('authInfo'));
    if (authInfo?.expiry) {
        const today = new Date();
        const expired = new Date(authInfo.expiry);
        if (expired < today) {
            await removeItem();
            return redirect('/login');
        }
    } else {
        await removeItem();
        return redirect('/login');
    }
    return authInfo;
}
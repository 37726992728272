import { useRef, useState } from "react";
import { CSVLink } from 'react-csv';

// import { getParticipantList } from '../utils/api';



export default function ExportCSV({series_id}) { 

    const csvLinkRef = useRef(null);

    // const headers = [
    //     { label: "First Name", key: "First Name" },
    //     { label: "Last Name", key: "Last Name" },
    //     { label: "DOB", key: "DOB" },
    //     { label: "Age", key: "age" }
    //   ];

      const [fileHeaders] = useState([
        { label: "First Name", key: "First Name" },
        { label: "Last Name", key: "Last Name" },
        { label: "DOB", key: "DOB" },
        { label: "SeriesId", key: "SeriesId"},
        // { label: "participant_id", key: "participant_id"},
        { label: "registration_date", key: "registration_date"},
        { label: "series_name", key: "series_name" }
      ]);

      const fileData = series_id.props;

      
      
      const csvReport = {
        data: fileData,
        headers: fileHeaders,
        filename: 'RoadRaceParticipants.csv'
      };

    // const [fileData ] = ['matt', 'schreiber'];

    // const [fileHeaders] = useState([
    //     'First Name', 'Last Name'  
    // ]);




    const handleDataFetch = async() => {
        csvLinkRef.current.link.click();
        return;
    };


    return (
        <div>
            {/* {fileData?.length &&  */}
                {/* <CSVLink style={{color: 'blue'}}
                    headers={fileHeaders}
                    data={fileData}
                    filename="RoadRaceParticipants"
                    target="_blank"
                >
                    Export to CSV
                </CSVLink> */}

                <CSVLink 
                ref={csvLinkRef} 
                hidden {...csvReport}>Export to CSV
                </CSVLink>
                {/* <a href="/" target={'_blank'} hidden ref={csvLinkRef}>Click Me</a> */}

                {/* <button onClick={handleDataFetch}>Click Button</button> */}
                <button type="button" onClick={handleDataFetch} className="btn btn-primary btn-hermes">Export Participants</button>

            <p className="fw-lighter mt-1">Download Participant List as a CSV</p>
        </div>
    )

}
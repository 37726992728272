import { Form, useLoaderData, useActionData, useNavigation, Link, redirect } from 'react-router-dom';
import { getRaceList, updateRaceInfo, getRaceTypeList } from '../utils/api';
import { formatDateString } from '../utils/helpers';



export default function EditRacePage() {
    
    const data = useActionData();
    const navigation = useNavigation();
    const loaderData = useLoaderData();

    return (    
        <Form method="post">
            <div className="row g-3 mt-2 justify-content-start">
                <div className='col-md-4'></div>
                <div className='col-md-3 ps-4'><Link className='hermes-link-color' to={"/admin/races"}>Back</Link></div>
                <div className='col-md-4'></div>
            </div>
            <div className="row g-3 mt-2 justify-content-center">
            <h2 className='text-center hermes-header'>Edit Race</h2>
                <div className="col-md-4"></div>
                <div className="col-md-3">
                {data && data.status && 
                    <div className="mb-3">
                            <input type={"text"} className="form-control text-danger border border-danger" disabled id="msg" name="msg" value={data.message}></input>
                    </div>
                }
                    <div className="mb-3">
                        <label htmlFor="raceName" className="form-label">Race Name <span style={{color: 'red'}}>*</span></label>
                        <input type={"text"} className="form-control" id="raceName" name="raceName" required defaultValue={loaderData.raceList[0].race_name}></input>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="raceDate" className="form-label">Race Date <span style={{color: 'red'}}>*</span></label>
                        <input type={"date"} className="form-control" id="raceDate" name="raceDate" required defaultValue={formatDateString(loaderData.raceList[0].race_date)}></input>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="raceDistance" className="form-label">Race Distance <span style={{color: 'red'}}>*</span></label>
                        <input type={"number"} step='0.01' className="form-control" id="raceDistance" name="raceDistance" placeholder='Enter in Miles' required defaultValue={loaderData.raceList[0].race_distance}></input>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="participationPoints" className="form-label">Participation Points <span style={{color: 'red'}}>*</span></label>
                        <input type={"text"} className="form-control" id="participationPoints" name="participationPoints" required defaultValue={loaderData.raceList[0].participation_points}></input>
                    </div>
                    <div className="mb-3">
                        <label className="form-label text-secondary" htmlFor="racetype">Race Type <span style={{color: 'red'}}>*</span></label>
                        <select id='raceTypeSelect' defaultValue={loaderData.raceList[0].race_type_id} className="form-select font-monospace text-secondary" aria-label="Select Race Type" name="raceTypeSelect" required>

                            { loaderData.raceTypeList.map((rt) => <option key={rt.race_type_id} value={rt.race_type_id}>{rt.race_type_name}</option>)}
                            
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="raceUrl" className="form-label">Race Website URL</label>
                        <input type={"text"} className="form-control" id="raceUrl" name="raceUrl" defaultValue={loaderData.raceList[0].race_url}></input>
                    </div>
                    <button type="submit" disabled={navigation.state === 'submitting'} className="btn btn-primary btn-hermes">Update Race</button>
                </div>
                <div className="col-md-4"></div>
            </div>
        </Form>
    );
}

export async function action ({ request, params }) {


    const formData = await request.formData();
    const post = {
        race_name: formData.get('raceName'),
        race_date: formData.get('raceDate'),
        race_distance: formData.get('raceDistance'),
        race_url: formData.get('raceUrl'),
        participation_points: formData.get('participationPoints'),
        race_type_id: formData.get('raceTypeSelect')
    }

    try {
        const race_id = params.race_id;
        post.race_id = race_id;
        await updateRaceInfo(post);
        return redirect('/admin/races');
    } catch (err) {
        return err;
    }

}

export async function loader ({params}) {

    const race_id = params.race_id;
    const raceList = await getRaceList(race_id);
    const raceTypeList = await getRaceTypeList();
    return {raceList: raceList, raceTypeList: raceTypeList};

}



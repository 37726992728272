// methods for results

export async function getSeriesStandings(series_id, category, fullName) {
    try {
        let seriesStandings = null;
        if (category === 'Overall') {
            seriesStandings = await fetch (`/api/results/runner-overall/${series_id}?full_name=${fullName}`);
        } else if (category === 'Male' || category === 'Female') {
            seriesStandings = await fetch(`/api/results/runner-gender/${series_id}?full_name=${fullName}&categorySelect=${category}`);
        } else {
            seriesStandings = await fetch(`/api/results/runner-agegroup/${series_id}?categorySelect=${category}&full_name=${fullName}`);
        }
        if (!seriesStandings.ok) {
            // eslint-disable-next-line
            throw { message: 'Failed to fetch Series Standings', status: 500 };
        }
        const seriesStandingsArray = await seriesStandings.json();
        return await seriesStandingsArray;
    } catch (err) {
        // eslint-disable-next-line
        throw {message: 'Failed to fetch Series Standings', status: 500 };
    }
}


export async function getListAgeGroupsAndGenders(series_id) {

    try {
        const results = await fetch(`/api/results/gender-age-group?series_id=${series_id}`);
        if (!results.ok) {
            // eslint-disable-next-line
            throw { message: 'Failed to fetch Series Standings', status: 500 };
        }
        const categoryList = await results.json();
        return await categoryList;
    } catch (err) {
        // eslint-disable-next-line
        throw {message: 'Failed to fetch Category List', status: 500 };
    }

}

export async function getParticipantResultDetails(series_id, participant_series_id) {

    try {
        const results = await fetch (`/api/results/runner-results/${series_id}/${participant_series_id}`);
        if(!results.ok) {
            // eslint-disable-next-line
            throw { message: 'Failed to fetch Results', status: 500 };
        }
        const resultDetails = await results.json();
        return await resultDetails;
    } catch (err) {
                // eslint-disable-next-line
                throw {message: 'Failed to fetch Results', status: 500 };
    }
}

export async function getParticipantOverallInfo(series_id, participant_series_id) {

    try {
        const results = await fetch (`/api/results/runner-overall/${series_id}/${participant_series_id}`);
        if(!results.ok) {
            // eslint-disable-next-line
            throw { message: 'Failed to fetch Results', status: 500 };
        }
        const resultDetails = await results.json();
        return await resultDetails;
    } catch (err) {
                // eslint-disable-next-line
                throw {message: 'Failed to fetch Results', status: 500 };
    }
}

export async function getParticipantsByName(series_id, full_name) {

    try {
        const results = await fetch (`/api/results/runner-overall/${series_id}?full_name=${full_name}`);
        if(!results.ok) {
            // eslint-disable-next-line
            throw { message: 'Failed to fetch Results', status: 500 };
        }
        const resultDetails = await results.json();
        return await resultDetails;
    } catch (err) {
                // eslint-disable-next-line
                throw {message: 'Failed to fetch Results', status: 500 };
    }
}

export async function getAvgRaceTimeByGender(series_id) {
    try {
        // get average time for a race by gender
        const results = await fetch (`/api/results/metrics/avg-time/${series_id}`);
        if(!results.ok) {
            // eslint-disable-next-line
            throw { message: 'Failed to fetch Results', status: 500 };
        }
        const resultDetails = await results.json();
        return await resultDetails;
    } catch (err) {
                // eslint-disable-next-line
                throw {message: 'Failed to fetch Results', status: 500 };
    }
}

export async function getSeriesStats(series_id) {

    try {
        // get series stats, includes averages/std deviation
        const seriesStats = await fetch(`/api/results/seriesstats/export/${series_id}`);
        if(!seriesStats.ok) {
            // eslint-disable-next-line
            throw { message: 'Failed to fetch Series Stats', status: 500 };
        }
        const seriesStatsList = await seriesStats.json();
        return await seriesStatsList;

    }catch (err) {
        // eslint-disable-next-line
        throw {message: 'Failed to fetch Series Stats', status: 500 };
    }
}

// End methods related to results

// Methods related to Series

export async function getSeriesList() {

    try {
        const seriesList = await fetch(`/api/series`);
        if (!seriesList.ok) {
            // eslint-disable-next-line
            if (seriesList.status === 401) {
                // eslint-disable-next-line
                throw { message: 'Failed to fetch List of Series', status: 401 }
            }
            // eslint-disable-next-line
            throw { message: 'Failed to fetch List of Series', status: 500 };
        }
        const seriesListData = await seriesList.json();
        return await seriesListData;
    } catch (err) {
        if (err.status === 401) {
            // eslint-disable-next-line
            throw { message: 'Failed to fetch List of Series', status: 401 }
        }
        // eslint-disable-next-line
        throw {message: 'Failed to fetch List of Series', status: 500 };
    }
}

export async function getSeriesInfo(series_id) {

    try {
        const seriesList = await fetch(`/api/series/${series_id}`);
        if (!seriesList.ok) {
            // eslint-disable-next-line
            if (seriesList.status === 401) {
                // eslint-disable-next-line
                throw { message: 'Failed to fetch Series Information', status: 401 }
            }
            // eslint-disable-next-line
            throw { message: 'Failed to fetch Series Information', status: 500 };
        }
        const seriesListData = await seriesList.json();
        return await seriesListData;
    } catch (err) {
        if (err.status === 401) {
            // eslint-disable-next-line
            throw { message: 'Failed to fetch List of Series', status: 401 }
        }
        // eslint-disable-next-line
        throw {message: 'Failed to fetch List of Races', status: 500 };
    }
}


// no login required
export async function getSeriesInfoResults(series_id) {

    try {
        const seriesList = await fetch(`/api/results/series/${series_id}`);
        if (!seriesList.ok) {
            // eslint-disable-next-line
            if (seriesList.status === 401) {
                // eslint-disable-next-line
                throw { message: 'Failed to fetch Series Information', status: 401 }
            }
            // eslint-disable-next-line
            throw { message: 'Failed to fetch Series Information', status: 500 };
        }
        const seriesListData = await seriesList.json();
        return await seriesListData;
    } catch (err) {
        if (err.status === 401) {
            // eslint-disable-next-line
            throw { message: 'Failed to fetch List of Series', status: 401 }
        }
        // eslint-disable-next-line
        throw {message: 'Failed to fetch List of Races', status: 500 };
    }
}

export async function updateSeriesInfo(seriesData) {
    try {
        if (!seriesData.series_name || !seriesData.series_age_date) {
            // eslint-disable-next-line
            throw {status: "Missing Series Information", code: 422}
        }

        const response = await fetch(`/api/update-series`, {
            method: 'PUT',
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(seriesData)
        });

        if(!response.ok) {
            if (response.status === 401) {
                // eslint-disable-next-line
                throw { message: 'Failed to fetch List of Participants', status: 401 }
            }
            // eslint-disable-next-line
            throw {status: 'Unable to Update Series Information', code: response.status};
        }
    
        const numUpdated = await response.json();
        return numUpdated;
    } catch (err) {
        if (err.status === 401) {
        // eslint-disable-next-line
            throw { message: 'Failed to fetch List of Participants', status: 401 }
        }
        // eslint-disable-next-line
        throw {status: 'Failed to Update Series Information', code: 500 };;
    }

}

export async function getSeriesTypes() {
    try {
        const seriesList = await fetch(`/api/results/seriestypes`);
        if (!seriesList.ok) {
            // eslint-disable-next-line
            if (seriesList.status === 401) {
                // eslint-disable-next-line
                throw { message: 'Failed to fetch List of Series', status: 401 }
            }
            // eslint-disable-next-line
            throw { message: 'Failed to fetch List of Series', status: 500 };
        }
        const seriesListData = await seriesList.json();
        return await seriesListData;
    } catch (err) {
        if (err.status === 401) {
            // eslint-disable-next-line
            throw { message: 'Failed to fetch List of Series', status: 401 }
        }
        // eslint-disable-next-line
        throw {message: 'Failed to fetch List of Series', status: 500 };
    }
}

export async function getSeriesCatName(series_id) {
    try {
        const seriesList = await fetch(`/api/results/seriestypes/${series_id}`);
        if (!seriesList.ok) {
            // eslint-disable-next-line
            if (seriesList.status === 401) {
                // eslint-disable-next-line
                throw { message: 'Failed to fetch List of Series', status: 401 }
            }
            // eslint-disable-next-line
            throw { message: 'Failed to fetch List of Series', status: 500 };
        }
        const seriesListData = await seriesList.json();
        return await seriesListData;
    } catch (err) {
        if (err.status === 401) {
            // eslint-disable-next-line
            throw { message: 'Failed to fetch List of Series', status: 401 }
        }
        // eslint-disable-next-line
        throw {message: 'Failed to fetch List of Series', status: 500 };
    }
}

// returns list of years for a given series
export async function getSeriesYearsByCatId(series_category_id) {
    try {
        const seriesYears = await fetch(`/api/results/seriesyears/${series_category_id}`);
        if (!seriesYears.ok) {
            // eslint-disable-next-line
            if (seriesYears.status === 401) {
                // eslint-disable-next-line
                throw { message: 'Failed to fetch List of Series', status: 401 }
            }
            // eslint-disable-next-line
            throw { message: 'Failed to fetch List of Series', status: 500 };
        }
        const seriesYearstData = await seriesYears.json();
        return await seriesYearstData;
    } catch (err) {
        if (err.status === 401) {
            // eslint-disable-next-line
            throw { message: 'Failed to fetch List of Series', status: 401 }
        }
        // eslint-disable-next-line
        throw {message: 'Failed to fetch List of Races', status: 500 };
    }
}

// End of Methods related to Series

// Methods related to Races Globally (not added to a series)

export async function getRaceList(race_id) {

    try {
        const raceList = await fetch(`/api/races/${race_id}`);
        if (!raceList.ok) {
            // eslint-disable-next-line
            if (raceList.status === 401) {
                // eslint-disable-next-line
                throw { message: 'Failed to fetch List of Races', status: 401 }
            }
            // eslint-disable-next-line
            throw { message: 'Failed to fetch List of Races', status: 500 };
        }
        const raceListData = await raceList.json();
        return await raceListData;
    } catch (err) {
        if (err.status === 401) {
            // eslint-disable-next-line
            throw { message: 'Failed to fetch List of Series', status: 401 }
        }
        // eslint-disable-next-line
        throw {message: 'Failed to fetch List of Races', status: 500 };
    }
}

export async function getRaceTypeList() {
    try {
        const raceTypes = await fetch(`/api/racetypes`);
        if (!raceTypes.ok) {
            // eslint-disable-next-line
            if (raceTypes.status === 401) {
                // eslint-disable-next-line
                throw { message: 'Failed to fetch List of Race Types', status: 401 }
            }
            // eslint-disable-next-line
            throw { message: 'Failed to fetch List of Race Types', status: 500 };
        }
        const raceTypesData = await raceTypes.json();
        return await raceTypesData;
    } catch (err) {
        if (err.status === 401) {
            // eslint-disable-next-line
            throw { message: 'Failed to fetch List of Race Types', status: 401 }
        }
        // eslint-disable-next-line
        throw {message: 'Failed to fetch List of Race Types', status: 500 };
    }
}

export async function getRaceByName(race_name) {
    try {
        const raceList = await fetch(`/api/races/race-name?race_name=${race_name}`);
        if (!raceList.ok) {
            // eslint-disable-next-line
            if (raceList.status === 401) {
                // eslint-disable-next-line
                throw { message: 'Failed to fetch List of Races', status: 401 }
            }
            // eslint-disable-next-line
            throw { message: 'Failed to fetch List of Races', status: 500 };
        }
        const raceListData = await raceList.json();
        return await raceListData;
    } catch (err) {
        if (err.status === 401) {
            // eslint-disable-next-line
            throw { message: 'Failed to fetch List of Series', status: 401 }
        }
        // eslint-disable-next-line
        throw {message: 'Failed to fetch List of Races', status: 500 };
    }
}

export async function getAllRacesList() {

    try {
        const raceList = await fetch(`/api/races`);
        if (!raceList.ok) {
            // eslint-disable-next-line
            if (raceList.status === 401) {
                // eslint-disable-next-line
                throw { message: 'Failed to fetch List of Races', status: 401 }
            }
            // eslint-disable-next-line
            throw { message: 'Failed to fetch List of Races', status: 500 };
        }
        const raceListData = await raceList.json();
        return await raceListData;
    } catch (err) {
        if (err.status === 401) {
            // eslint-disable-next-line
            throw { message: 'Failed to fetch List of Series', status: 401 }
        }
        // eslint-disable-next-line
        throw {message: 'Failed to fetch List of Races', status: 500 };
    }
}

// search for list of races not already in a series
export async function getRacesNotInSeriesList(series_id, race_name) {

    try {
        const raceList = await fetch(`/api/race-not-series/${series_id}?race_name=${race_name}`);
        if (!raceList.ok) {
            // eslint-disable-next-line
            if (raceList.status === 401) {
                // eslint-disable-next-line
                throw { message: 'Failed to fetch List of Races', status: 401 }
            }
            // eslint-disable-next-line
            throw { message: 'Failed to fetch List of Races', status: 500 };
        }
        const raceListData = await raceList.json();
        return await raceListData;
    } catch (err) {
        if (err.status === 401) {
            // eslint-disable-next-line
            throw { message: 'Failed to fetch List of Series', status: 401 }
        }
        // eslint-disable-next-line
        throw {message: 'Failed to fetch List of Races', status: 500 };
    }
}

export async function postNewRace(raceData) {

    try {
        if (!raceData.race_name || !raceData.race_date || !raceData.race_distance || !raceData.participation_points || !raceData.race_type_id) {
            // eslint-disable-next-line
            throw {message: "Missing Race Information", status: 422}
        }
        const response = await fetch(`/api/new-race`, {
            method: 'POST',
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(raceData)
        });

        if(!response.ok) {
            // eslint-disable-next-line
            throw {message: 'Unable to Save Race Information', status: response.status};
        }
    
        const newRaceId = await response.json();
        return newRaceId;
    } catch (err) {
        // eslint-disable-next-line
        throw err;
    }

}

export async function deleteGlobalRace(raceId) {

    try {

        const response = await fetch(`/api/race`, {
            method: 'DELETE',
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(raceId)
        });

        if(!response.ok) {
            // eslint-disable-next-line
            throw {message: 'Error Deleting Race', status: response.status};
        }
    
        const numDeleted = await response.json();
        return numDeleted;
    } catch (err) {
        // eslint-disable-next-line
        throw err;
    }

}

export async function updateRaceInfo(raceData) {
    try {
        if (!raceData.race_name || !raceData.race_date || !raceData.race_distance || !raceData.participation_points || !raceData.race_type_id) {
            // eslint-disable-next-line
            throw {message: "Missing Race Information", status: 422}
        }
        const response = await fetch(`/api/update-race`, {
            method: 'PUT',
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(raceData)
        });

        if(!response.ok) {
            // eslint-disable-next-line
            throw {message: 'Unable to Save Race Information', status: response.status};
        }
    
        const newRaceId = await response.json();
        return newRaceId;
    } catch (err) {
        if (err.status === 401) {
            // eslint-disable-next-line
            throw { message: 'Failed to fetch List of Series', status: 401 }
        }
        // eslint-disable-next-line
        throw err;
    }

}
// End Methods related to Races Globally (not added to a series)

// Methods for managing races in a series

export async function getSeriesRaceList(series_id) {

    try {
        const raceList = await fetch(`/api/races-series/${series_id}`);
        if (!raceList.ok) {
            // eslint-disable-next-line
            if (raceList.status === 401) {
                // eslint-disable-next-line
                throw { message: 'Failed to fetch List of Races', status: 401 }
            }
            // eslint-disable-next-line
            throw { message: 'Failed to fetch List of Races', status: 500 };
        }
        const raceListData = await raceList.json();
        return await raceListData;
    } catch (err) {
        if (err.status === 401) {
            // eslint-disable-next-line
            throw { message: 'Failed to fetch List of Series', status: 401 }
        }
        // eslint-disable-next-line
        throw {message: 'Failed to fetch List of Races', status: 500 };
    }
}

export async function postSeriesRace(raceData) {
    // raceData should have keys series_id and race_id
    try {
        if (!raceData.race_id || !raceData.series_id) {
            // eslint-disable-next-line
            throw {message: "Missing Race or Series Information", status: 422}
        }
        const response = await fetch(`/api/races-series`, {
            method: 'POST',
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(raceData)
        });

        if(!response.ok) {
            // eslint-disable-next-line
            throw {message: 'Unable to Save Race to Series', status: response.status};
        }
    
        const newSeriesRaceId = await response.json();
        return newSeriesRaceId;

    } catch (err) {
        if (err.status === 401) {
            // eslint-disable-next-line
            throw { message: 'Failed to fetch List of Series', status: 401 }
        }
        // eslint-disable-next-line
        throw err;
    }

}

// delete race from a series
export async function deleteRaceFromSeries(raceId, seriesId) {

    try {
        const response = await fetch(`/api/races-series`, {
            method: 'DELETE',
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({race_id: raceId, series_id: seriesId})
        });

        if(!response.ok) {
            // eslint-disable-next-line
            throw {message: 'Error Deleting Race', status: response.status};
        }
    
        const numDeleted = await response.json();
        return numDeleted;
    } catch (err) {
        // eslint-disable-next-line
        throw err;
    }

}
// End Methods related to Series Races

// Score Races Methods

export async function postResults(resultsData) {


    try {
        if (!resultsData) {
            // eslint-disable-next-line
            throw {message: "No Results Data Found", status: 422}
        }
        const response = await fetch(`/api/scoring/results-import`, {
            method: 'POST',
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(resultsData)
        });

        if(!response.ok) {
            // eslint-disable-next-line
            throw {message: 'Unable to import results', status: response.status};
        }
    
        const count = await response.json();
        return count;
    } catch (err) {
        // eslint-disable-next-line
        throw err;
    }

}

// delete results for a race
export async function deleteRaceResults(raceId, seriesId) {

    try {
        const response = await fetch(`/api/scoring/race-results`, {
            method: 'DELETE',
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({race_id: raceId, series_id: seriesId})
        });

        if(!response.ok) {
            // eslint-disable-next-line
            throw {message: 'Error Deleting Results', status: response.status};
        }
    
        const numDeleted = await response.json();
        return numDeleted;
    } catch (err) {
        // eslint-disable-next-line
        throw err;
    }

}

// finalize results for age group awards
export async function postFinalResults(seriesId) {

    try {
        if (!seriesId) {
            // eslint-disable-next-line
            throw {message: "No Results Data Found", status: 422}
        }
        const response = await fetch(`/api/scoring/finalize-awards`, {
            method: 'POST',
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({series_id: seriesId})
        });

        if(!response.ok) {
            // eslint-disable-next-line
            throw {message: 'Unable to import results', status: response.status};
        }
    
        const count = await response.json();
        return count;
    } catch (err) {
        // eslint-disable-next-line
        throw err;
    }

}


// End Score Race Methods

// Methods related to participants
export async function getParticipantList(series_id) {

    try {
        const participantList = await fetch(`/api/participant-list/${series_id}`);
        if (!participantList.ok) {
            // eslint-disable-next-line
            if (participantList.status === 401) {
                // eslint-disable-next-line
                throw { message: 'Failed to fetch List of Participants', status: 401 }
            }
            // eslint-disable-next-line
            throw { message: 'Failed to fetch List of Participants', status: 500 };
        }
        const participantListData = await participantList.json();
        return await participantListData;
    } catch (err) {
        if (err.status === 401) {
            // eslint-disable-next-line
            throw { message: 'Failed to fetch List of Series', status: 401 }
        }
        // eslint-disable-next-line
        throw {message: 'Failed to fetch List of Participants', status: 500 };
    }
}

export async function postParticipants(participantData) {

    let participant = {};
    participant.participants = participantData.data;
    participant.series_id = participantData.series_id;

    try {
        if (!participant) {
            // eslint-disable-next-line
            throw {message: "No Participant Data Found", status: 422}
        }
        const response = await fetch(`/api/participant-import`, {
            method: 'POST',
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(participant)
        });

        if(!response.ok) {
            // eslint-disable-next-line
            throw {message: 'Import Failed! Verify Column Names Are Correct', status: response.status};
        }
    
        const count = await response.json();
        return count;
    } catch (err) {
        // eslint-disable-next-line
        throw err;
    }

}

export async function updateParticipant(participant) {
    try {
        if (!participant.first_name || !participant.last_name || !participant.gender 
                || !participant.dob || !participant.city || !participant.state_name 
                || !participant.registration_date || !participant.participant_id || !participant.participant_series_id) {
            // eslint-disable-next-line
            throw {message: "Missing Participant Information", status: 422}
        }
        const response = await fetch(`/api/update-participant`, {
            method: 'PUT',
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(participant)
        });

        if(!response.ok) {
            // eslint-disable-next-line
            throw {message: 'Unable to Update Participant Information', status: response.status};
        }
    
        const numUpdated = await response.json();
        return numUpdated;
    } catch (err) {
        if (err.status === 401) {
            // eslint-disable-next-line
            throw { message: 'Failed to Update Participant Information', status: 401 }
        }
        // eslint-disable-next-line
        throw err;
    }

}
// End Methods related to participants

// Methods for Users
export async function postLoginUser(userData) {

    try {
        if (!userData.user_email || !userData.user_password) {
            // eslint-disable-next-line
            throw {message: "Invalid Email or Password", status: 422}
        }
        const response = await fetch(`/api/auth/authenticate`, {
            method: 'POST',
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData)
        });

        if(!response.ok) {
            // eslint-disable-next-line
            throw {message: 'Invalid Email or Password', status: response.status};
        }
    
        const loggedInUser = await response.json();
        return loggedInUser;
    } catch (err) {
        // eslint-disable-next-line
        throw err;
    }

}


export async function logoutUser() {
    

    try {
        const response = await fetch('/api/auth/logout');
        if (!response.ok) {
            // eslint-disable-next-line
            throw {message: 'Unable to Logout of Server', status: response.status};
        } 
        return response
    } catch (err) {
        throw err;
    }

}

// create a new user
export async function newUser(userData) {
    try {
        if (!userData.user_email || !userData.user_password || !userData.user_first_name || !userData.user_last_name) {
            // eslint-disable-next-line
            throw {message: "Please fill out all fields", status: 422}
        }
        const response = await fetch(`/api/users/new-user`, {
            method: 'POST',
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData)
        });
        if(!response.ok) {
            // eslint-disable-next-line
            throw {message: 'Unable to create new user', status: response.status};
        }
    
        const msg = await response.json();

        // api responds with code 600 to indicate user already exists.
        if (msg.code === 600) {
            // eslint-disable-next-line
            throw msg;
        } else {
            return msg;
        }

    } catch (err) {
        // eslint-disable-next-line
        throw err;
    }
}

export async function getUserList() {

    try {
        const userList = await fetch(`/api/users/user-list`);
        if (!userList.ok) {
            // eslint-disable-next-line
            if (userList.status === 401) {
                // eslint-disable-next-line
                throw { message: 'Failed to fetch List of Users', status: 401 }
            }
            // eslint-disable-next-line
            throw { message: 'Failed to fetch List of Users', status: 500 };
        }
        const userListData = await userList.json();
        return await userListData;
    } catch (err) {
        if (err.status === 401) {
            // eslint-disable-next-line
            throw { message: 'Failed to fetch List of Users', status: 401 }
        }
        // eslint-disable-next-line
        throw {message: 'Failed to fetch List of Users', status: 500 };
    }
}

export async function updateUser(userData) {
    try {
        if (!userData.user_email || !userData.user_first_name || !userData.user_last_name) {
            // eslint-disable-next-line
            throw {message: "Please fill out all fields", status: 422}
        }
        const response = await fetch(`/api/users/update-user`, {
            method: 'PUT',
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData)
        });

        if(!response.ok) {
            // eslint-disable-next-line
            throw {message: 'Unable to Update User Information', status: response.status};
        }
    
        const numUpdated = await response.json();
        return numUpdated;
    } catch (err) {
        if (err.status === 401) {
            // eslint-disable-next-line
            throw { message: 'Failed to Update Participant Information', status: 401 }
        }
        // eslint-disable-next-line
        throw err;
    }

}
// End Methods for Users


// Methods for Age Groups

export async function postAgeGroups(ageGroupsData) {


    try {
        if (!ageGroupsData) {
            // eslint-disable-next-line
            throw {message: "No Age Groups Data Found", status: 422}
        }
        const response = await fetch(`/api/scoring/age-groups`, {
            method: 'POST',
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(ageGroupsData)
        });

        if(!response.ok) {
            // eslint-disable-next-line
            throw {message: 'Unable to save age groups', status: response.status};
        }
    
        const msg = await response.json();
        return msg;
    } catch (err) {
        // eslint-disable-next-line
        throw err;
    }

}
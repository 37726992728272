import { formatDistance } from '../utils/helpers';

export default function StandingDetailResults(detailedResults) {

    for (let i=0; i<detailedResults.props.length; i++) {
        let chipTime = detailedResults.props[i].chip_time.split(':');
        if (chipTime[0] === '00') {
            detailedResults.props[i].chip_time = chipTime[1] + ':' + chipTime[2];
        } else if (chipTime[0][0] === '0'){
            detailedResults.props[i].chip_time = chipTime[0][1] + ':' + chipTime[1] + ':' + chipTime[2]
        }
    }

    return (
        <div className="table-responsive  shadow bg-white p-2">
        <table className="table table-sm table-striped caption-top">
            <caption className="">Race List - {detailedResults.props[0].series_year} </caption>
            <thead>
                <tr>
                <th className="standings-medium-font text-muted text-start" scope="col">Race Name</th>
                <th className="standings-medium-font text-muted text-center" scope="col">Distance</th>
                <th className="standings-medium-font text-muted text-center" scope="col">Race Points</th>
                <th className="standings-medium-font text-muted text-center" scope="col">Time</th>
                <th className="standings-medium-font text-muted text-center" scope="col">Pace</th>
                { detailedResults.showAgeGrade ?
                <>
                <th className="standings-medium-font text-muted text-center" scope="col">Age Grade %</th>                           
                </> : ""
            }
                <th className="standings-medium-font text-muted text-center" scope="col">Gender Place</th>
            { detailedResults.showAgeGroups ?
                <>
                <th className="standings-medium-font text-muted text-center" scope="col">Age Place</th>                           
                </> : ""
            }
                </tr>
            </thead>
            <tbody>
            { detailedResults.props.map((rank, index) =>
                <tr key={rank.result_id}>
                    <td className="standings-medium-font text-start race-list"><a href={rank.race_url} target="_blank" rel="noreferrer">{rank.race_name}</a></td>
                    <td className="standings-medium-font text-center race-list">{formatDistance(rank.race_distance)}</td>
                    <td className={`standings-medium-font text-center ${index < 7 ? 'fw-bold' : ''}`}>{index < 7 ? rank.race_points : rank.participation_points}</td>
                    <td className="standings-medium-font text-center">{rank.chip_time}
                    </td>
                    <td className="standings-medium-font text-center"> 
                    {rank.pace.split(':')[1][0] === '0' ?
                        rank.pace.split(':')[1][1] + ':' + rank.pace.split(':')[2] :
                        rank.pace.split(':')[1] + ':' + rank.pace.split(':')[2]
                    }
                    </td>
                    { detailedResults.showAgeGrade ?
                            <>
                                <td className="standings-medium-font text-center">
                                    {rank.age_grade} 
                                </td>                                  
                            </> : ""
                            }
                    <td className="standings-medium-font text-center">{rank.gender_place === 0 ? 'N/A' : rank.gender_place}</td>
                    { detailedResults.showAgeGroups ?
                            <>
                                <td className="standings-medium-font text-center">
                                    {rank.age_group_place === 0 ? '-' : rank.age_group_place} 
                                </td>                                  
                            </> : ""
                            }
                </tr>
            )}
            </tbody>
        </table>

        </div>
    )

}

import { redirect } from 'react-router-dom';
import { updateParticipant } from '../utils/api';

export default function UpdateParticipant () {

    return ( <div>Error Happened</div>);
}

export async function action({ request }) {

    const formData = await request.formData();
    const participant = Object.fromEntries(formData);
    try {
        const numUpdated = await updateParticipant(participant);
        let msg = {status: '', code: 0}
        if (numUpdated?.err) {
            msg.status = 'Unable to Update Participant';
            msg.code = 500;            
        }
        return msg.status = numUpdated;   
    }    
        catch (err) {
        return err;
    }


}

export function loader() {
    redirect ('/admin');
}
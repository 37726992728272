export default function StandingDetailHeader(details) {

    return (
        <>
            <div className="card shadow mb-2" key={details.props.success[0].participant_series_id}>
                <h6 className="card-header">{details.props.success[0].first_name + ' ' + details.props.success[0].last_name}</h6>
                <div className="card-body">
                    <div className="hstack gap-2">
                        <div className="pe-2 standings-small-titles">{details.props.success[0].gender}:{details.props.success[0].participant_series_age}</div>
                        <div className="vr"></div>
                        <div className="ps-2 standings-small-titles">{details.props.success[0].city}, {details.props.success[0].state_name} </div>

                    </div>
                    <hr/>
                    <div className="hstack gap-2">
                    <div className="text-muted standings-small-titles">Total Points:</div>
                        <div>{details.props.success[0].overall_points}</div>
                        <div className="vr"></div>
                        <div className="text-muted standings-small-titles">Gender Place:</div>
                        {/* <div>{details.props.success[0].gender_place}</div> */}
                        { details.props.success[0].gender_place === 10000 ? <div style={{color: "red"}}>DNQ</div> : <div>{details.props.success[0].gender_place}</div>}
                        <div className="vr"></div>
                        { details.showAgeGroups ?
                            <>
                                <div className="text-muted standings-small-titles">Age Place</div>
                                 {/* <div>{details.props.success[0].age_group_place > 0 ? details.props.success[0].age_group_place : '-'}</div> */}
                                 { details.props.success[0].age_group_place === 10000 ? <div style={{color: "red"}}>DNQ</div> : <div>{details.props.success[0].age_group_place > 0 ? details.props.success[0].age_group_place : '-'}</div>}
                            </> : ""
                            }

                    </div>
                    <hr/>
                    <div className="hstack gap-2">
                        <div className={"standings-small-titles " + (details.props.success[0].num_races >= details.props.success[0].min_races ? "text-success" : "text-muted")}>Total Races:</div>
                        <div className={details.props.success[0].num_races >= details.props.success[0].min_races ? "text-success" : ""}>{details.props.success[0].num_races}</div>
                        <div className="vr"></div>
                        <div className={"text-muted standings-small-titles "}>Total Miles:</div>
                        <div >{details.props.success[0].total_miles}</div>
                    </div>
                    <hr/>
                </div>
            </div>
        </>

        // <div>
        //     <StandingsDisplay props={details} />
        // </div>
    )
}
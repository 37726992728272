import { useState, useRef } from 'react';
import { useFetcher, redirect, useNavigation } from 'react-router-dom';
import MsgComponent from '../components/MsgComponent';
import { getSeriesRaceList } from '../utils/api';
import { formatDateString } from '../utils/helpers';



export default function SeriesRaces(raceData) {
    
    // const navigate = useNavigate();
    const navigation = useNavigation;
    const fetcher = useFetcher();  
    const fetcherUpload = useFetcher();
    const fetcherDeleteResults = useFetcher();

    const msgRef = useRef(null);

    const [confirmDialog, setConfirmDialog] = useState(''); 
    const [raceId, setRaceId] = useState(null);
    const [seriesId, setSeriesId] = useState(null);
    
    let msg = {};

    const scrollToTop = () => {
        window.scrollTo({
            top: msgRef.current.offsetTop -50,
            behavior: "instant"
        });
    }

    if (fetcherUpload?.data) {
        msg = fetcherUpload.data;
        scrollToTop();
        for (let i=0; i<document.getElementsByName('fileUploader').length; i++) {
            document.getElementsByName('fileUploader')[i].value = null;
        }

        fetcherUpload.data = null;
    }

    if (fetcherDeleteResults?.data) {

        msg.status = fetcherDeleteResults.data + ' results were deleted';
        scrollToTop();
        fetcherDeleteResults.data = null;
    }

    const deleteResults = (race_id, series_id, race_name) => {
        setConfirmDialog('This will delete all the results for ' + race_name);
        setRaceId(race_id);
        setSeriesId(series_id)
    }

    const removeRace = (race_id, series_id, race_name) => {

        setConfirmDialog('This will delete ' + race_name + ' from the series.');
        setRaceId(race_id);
        setSeriesId(series_id)
        return;
    }

    const saveDelete = () => {
        if (fetcher.data?.code) {
            fetcher.data.code = 0;
        }
        scrollToTop();
    }
    
    return (
        <>
        <div className='row'>
         <div className='col-lg-3'></div>
         <div className='mt-3 col-lg-6'>
            <h2 className='text-center hermes-header pt-3' ref={msgRef}>Score Races</h2>
            <p className='text-muted text-center' style={{fontSize:'.9rem'}}>CSV column headers for importing a race (case sensitive): 
            <span className='fw-bold'> overall_place, chip_time, pace, Gender Place and participant_series_id, age_grade</span></p>    
        </div> 
         <div className='col-lg-3'></div>
        </div>
        
        { fetcher.data?.code >= 400 ? <MsgComponent msg={fetcher.data.status} /> : ''}
        <div className='row'>
        {msg.code >= 400 ? <MsgComponent msg={msg.status} /> : <span className='d-flex text-success justify-content-center align-items-center fs-3'>{msg.status}</span>
        }
            <div className='col-lg-3'></div>
            <div className='mt-3 col-lg-6'> 
            { raceData.raceData.map((race) => 
                <div key={race.race_id} className="card mb-3">
                    {/* <img src='2022-RRS_logo-1.jpg' style={{width:'65px'}} className='card-img-top' alt="Hermes Logo"></img> */}
                    <div className="card-body">
                        <div className='row'>
                            <div className='col'>
                                <h5 className="card-title pb-2">{race.race_name}</h5>
                            </div>
                            <div className='d-flex justify-content-end col'>
                                <button type="button" className="btn-close" data-bs-toggle="modal" data-bs-target="#confirmModal" onClick={() => removeRace(race.race_id, race.series_id, race.race_name)} aria-label="Close"></button>
                            </div>

                        </div>
                        <div className="card-subtitle mb-3 text-muted" style={{fontSize: ".90rem"}}>
                            {/* ID: {race.race_id}  */}
                            {/* <div className='vr mx-2'></div>  */}
                            Date: {formatDateString(race.race_date)} 
                            <div className='vr mx-2'></div> 
                            Participation Points: {race.participation_points} 
                            <hr/>
                            Race Distance: {race.race_distance + "M"}
                            <div className='vr mx-2'></div> 
                            Race Type: {race.race_type_name}
                            <hr />
                            <span>Website: {race.race_url}</span>
                        </div>
                        <hr/>
                        {/* <button type='button' className='btn btn-hermes me-2 btn-sm'>Edit Series</button> */}
                        <p className='fw-light'>Results:</p>
                        <fetcherUpload.Form method="post" encType='multipart/form-data' action={`/admin/results/postresults/${race.race_id}/${race.series_id}`}>
                            <div className=" row mb-3">
                                <div className='col-12 col-lg-6 pb-2'>
                                 <input type={"file"} className="form-control upload-csv " defaultValue={''} id="fileUploader" name="fileUploader"></input>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='d-flex align-items-center col-12 col-lg-6 pb-2'>
                                    <button type="submit" disabled={navigation.state === 'submitting'} className="btn btn-primary btn-hermes btn-sm">Import</button>
                                    <button type='button' className='btn btn-hermes mx-2 btn-sm' 
                                        data-bs-toggle="modal" data-bs-target="#deleteResultsModal" onClick={(event) => deleteResults(race.race_id, race.series_id, race.race_name)} >
                                    Delete
                                </button>
                                </div>
                            </div>      
                        </fetcherUpload.Form>
                    </div>
                </div>
            )}
            </div>
            <div className='col-lg-3'></div>
        </div>
        <div className="modal" id='confirmModal' tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title text-danger">Confirm Delete</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <p className='fw-bold'>{confirmDialog}</p>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <fetcher.Form
                         method="post"
                        action={`/admin/delete-seriesrace/${raceId}/${seriesId}`}
                        onSubmit={((event) =>  saveDelete(event))} 
                        >

                        <button type="submit" className='btn btn-danger' data-bs-dismiss="modal">Delete</button>
                    </fetcher.Form>
                    <button id='hiddenSubmitButton' hidden data-bs-dismiss="modal"></button>
                </div>
                </div>
            </div>
        </div>
        <div className="modal" id='deleteResultsModal' tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title text-danger">Confirm Delete</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <p className='fw-bold'>{confirmDialog}</p>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <fetcherDeleteResults.Form
                         method="post"
                        action={`/admin/delete-race-results/${raceId}/${seriesId}`}
                        onSubmit={((event) =>  saveDelete(event))} 
                        >

                        <button type="submit" className='btn btn-danger' data-bs-dismiss="modal">Delete</button>
                    </fetcherDeleteResults.Form>
                    <button id='hiddenSubmitButton' hidden data-bs-dismiss="modal"></button>
                </div>
                </div>
            </div>
        </div>

        </>
    );
}

export function loader () {

    try {
        const raceList = getSeriesRaceList();
        return raceList;
    } catch (err) {
        if (err.status === 401 ) {
            return redirect('/login');
        }
        return err; 
    }
}

import { redirect } from 'react-router-dom';
import Papa from 'papaparse';
import { postResults } from '../utils/api';

export default function PostResultsPage () {

    return ( <div>Error Happened</div>);
}

export async function action({ request, params }) {

    let msg = {status: '', code: 0};
    const series_id = params.series_id;
    const race_id = params.race_id;
    const formData = await request.formData();

    try {

        const numResults = await parse(formData.get('fileUploader'), race_id, series_id);
        msg.status= 'Imported ' + numResults.success.count + ' Results';
        return msg;
        
    } catch (err) {
        msg.status = 'Failed to import results: Please Verify Column Names.';
        msg.code = 500;
        return msg;
    }


}

async function parse(formData, race_id, series_id) {

    return new Promise((resolve, reject) => {
        try { 
            Papa.parse(formData, {
                header: true,
                skipEmptyLines: true,
                comments: true,
                complete: async function(results) {
                    if (results.data.length === 0) {
                        return resolve(results);
                    } else {
                        try {
                            //verify proper column names are in the uploaded data
                            if (!('overall_place' in results.data[0])) {
                                throw Error
                            }
                            if (!('chip_time' in results.data[0])) {
                                throw Error
                            } 
                            if (!('pace' in results.data[0])) {
                                throw Error
                            }
                            if (!('participant_series_id' in results.data[0])) {
                                throw Error
                            }
                            if (!('Gender Place' in results.data[0])) {
                                throw Error
                            }
                            if (!('age_grade' in results.data[0])) {
                                throw Error
                            }
                            
                            for (let i=0; i<results.data.length; i++) {
                                results.data[i].gender_place = results.data[i]['Gender Place'].split(':')[0]; 
                                let checkForPeriod = results.data[i].chip_time.split('.');
        
                                if (checkForPeriod.length > 1) {
                                    results.data[i].chip_time = checkForPeriod[0] + '.00';
                                } else {
                                    results.data[i].chip_time = results.data[i].chip_time + '.00';
                                }
        
                                results.data[i].pace = results.data[i].pace + '.00'
                            }
                            const res = {
                                series_id: series_id,
                                race_id: race_id,
                                results: results.data
                            };
                            const numResults = await postResults(res);
                            return resolve(numResults);
                        } catch (err) {
                            reject(err);
                        }

                    }
                }
            })
        } catch (err) {
            console.log(err);
            reject(err);
        }

    
    })

}

export function loader() {
    redirect ('/series');
}
import { useState, useRef } from "react";
import { useFetcher, redirect, useNavigate } from "react-router-dom"
import { getRacesNotInSeriesList, postSeriesRace } from "../utils/api";
import { formatDateString } from "../utils/helpers"
import MsgComponent from '../components/MsgComponent';

export default function SearchRaces(series_id) {

    const searchRacesFetcher = useFetcher();
    const navigate = useNavigate();
    const searchButton = useRef();

    const [addRaceErr, setAddRaceError] = useState(null);


    const addRace = async (race) => {
        try {
            const raceData = {
                race_id: race,
                series_id: series_id.series_id
            };
            await postSeriesRace(raceData);
            searchRacesFetcher.data = null;
            setAddRaceError(null);
            document.getElementById("raceName").value = '';
            document.getElementById("seriesId").value = '';
            navigate(`/admin/scoring/${series_id.series_id}`);
        } catch (err) {
            setAddRaceError(err);
            return (err);
        }

    }




    return (
        <>
        { addRaceErr?.status >= 400 ? <MsgComponent msg={addRaceErr.message} /> : ''}
        <div className="row">
            <div className="col-12 col-lg-3"></div>
            <div className="col-12 col-lg-12">
                <searchRacesFetcher.Form action={`/admin/scoring-series-list`} id="searchForRacesForm" >
                    <div className="row">
                        <div className="col-12 col-xl-6">
                        <input type={"text"} 
                            className="form-control" 
                            id="raceName" name="raceName" 
                            placeholder="Search Races"
                            aria-label="Search Races" 
                            onChange={(e) => {
                                let searchTable = document.getElementById("searchResults")
                                if (e.currentTarget.value === '') {
                                    searchTable.hidden = true;
                                } else {
                                    searchButton.current.click();
                                    if (searchTable) {
                                        searchTable.hidden = false;
                                    }
                                }
                            }} 
                            required >
                        </input>
                        <input type={"text"} hidden id="seriesId" name="seriesId" value={series_id.series_id} readOnly />
                        <p className="text-muted ps-2 pt-2">Add Race to Series</p>
                        </div>
                        <div className="col-12 col-xl-6">
                            <button className='btn btn-secondary' hidden ref={searchButton} role="search">Search</button>
                        </div>
                    </div>
                </searchRacesFetcher.Form>
            </div>
            <div className="col-12 col-lg-3"></div>
        </div>
        { searchRacesFetcher?.data ? 
            <div className="row" id="searchResults">
                <div className="col-lg-3"></div>
                <div className="col-lg-12">
                    <table className="table table-bordered table-striped caption-top">
                        <thead>
                            <tr>
                                <th scope="col">Race Name</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {searchRacesFetcher.data.raceList.map((race) => 
                        <tr key={race.race_id}>
                            <td>{race.race_name + ' - ' + formatDateString(race.race_date)}</td>
                            <td><button className='btn btn-secondary btn-sm' onClick={() => addRace(race.race_id)}>Add</button></td>
                        </tr>
                    )}
                            
                        </tbody>
            
                    </table>
                </div>
                <div className="col-lg-5"></div>
            </div> : ''
        }
        </>
    )
}

export async function loader({ request, params }) {
    const url = new URL(request.url);
    const raceName = url.searchParams.get("raceName"); 
    const seriesId = url.searchParams.get("seriesId");

    try {
        const raceList = await getRacesNotInSeriesList(seriesId, raceName);
        return {raceList, seriesId};

    } catch (err) {
        if (err.status === 401 ) {
            return redirect('/login');
        }
        return err; 
    }

}



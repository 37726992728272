import { Link, useLoaderData } from "react-router-dom";
import { getSeriesTypes } from "../utils/api";

export default function WelcomePage() {

    const seriesData = useLoaderData(); 
    
    
    return (
        <div className='row'>
        <div className='col-12 col-xl-4'></div>
        <div className='mt-3 col-12 col-xl-4'> 
        { seriesData.map((series) => 
            <div key={series.series_id} className="card mb-3">
            <div className='row mt-3'>
                <div className='col-xs-3 col-xl-3'></div>
                <div className='d-flex justify-content-center align-items-center col-xs-3 col-xl-6'>
                {series.series_name.includes("Road") ? 
                    <img src='/RRSHermesLogo.png' className='img-fluid hermes-logo-img' alt='Road Race Series Logo'></img>
                    : <img src='/fullmoon.png' className='img-fluid hermes-logo-img' alt='Full Moon Trail Series Logo'></img>
                }
                </div>
                <div className='col'></div>
            </div>
                <div className="card-body">
                    <h5 className="card-title text-center">{series.series_name}</h5>

                    <div className="card-subtitle mb-3 text-muted text-center" style={{fontSize: ".90rem"}}>
                            {/* Series ID: {series.series_category_id} */}
                        {/* <div className='vr mx-2'></div>  */}
                        <Link to={`/standings/${series.series_id}`} className='btn btn-hermes me-2 btn-sm btn-hermes-series' >Results</Link>
                        {series.series_name.includes("Road") ? 
                            <a className='btn btn-hermes btn-sm btn-hermes-series' target='_blank' rel='noreferrer' href={`https://www.roadraceseries.com/Race/RoadRaceSeries/Page-5`}>Rules</a>
                         : <a className='btn btn-hermes btn-sm btn-hermes-series' target='_blank' rel='noreferrer' href={`https://www.fullmoontrailseries.com/Race/FullMoon/Page-6`}>Rules</a>
                }
                    </div>
                </div>
            </div>
        )}
        </div>
        <div className='col-12 col-xl-4'></div>
    </div>
    );

    
}

export async function loader () {


    try {
        const seriesTypes = await getSeriesTypes();
        return seriesTypes;
    } catch (err) {
        return err; 
    }
}
import { useLoaderData, redirect, useNavigation, useFetcher } from 'react-router-dom';
import { useState } from 'react';
import { getUserList, updateUser } from '../utils/api';
import { checkPassword } from '../utils/helpers';
import MsgComponent from '../components/MsgComponent'

export default function ManageUsers() {

    const fetcher = useFetcher();
    const navigation = useNavigation();
    const userInfoData = useLoaderData();

    const [userId, setUserId] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userFirstName, setUserFirstName] = useState('');
    const [userLastName, setUserLastName] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    const [userPassword, setUserPassword] = useState('');
    const [confirmUserPassword, setConfirmUserPassword] = useState('');

    const editUserInfo = (userInfo) => {
        setUserId(userInfo.user_id);
        setUserEmail(userInfo.user_email);
        setUserFirstName(userInfo.user_first_name);
        setUserLastName(userInfo.user_last_name);
        setIsAdmin(userInfo.is_admin);
        setUserPassword(userInfo.user_password);
        setConfirmUserPassword(userInfo.confirm_user_password);
    } 

    return (
        
        <>
        <div className="table-responsive mt-4">
        { fetcher.data?.code >= 400 ? <MsgComponent msg={fetcher.data.status} /> : ''}
            <table className="table table-bordered table-striped caption-top">
                <caption className='mb-2'>
                    List of Users
                </caption>
                <thead>
                    <tr>
                        <th scope="col">First Name</th>
                        <th scope="col">Last Name</th>
                        <th scope="col">Email Address</th>
                        <th scope="col">Admin</th>
                        <th scope='col'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {userInfoData.userInfo.map((userInfo) => 
                     <tr key={userInfo.user_id}>
                        <td>{userInfo.user_first_name}</td>
                        <td>{userInfo.user_last_name}</td>
                        <td>{userInfo.user_email}</td>
                        <td>
                            <input type={"checkbox"} aria-describedby="is_admin" className="form-check-input ms-2" id="is_admin" name="is_admin" required readOnly checked={userInfo.is_admin}></input>
                        </td>
                        <td>
                            <button type='button' className='btn btn-hermes mx-2 btn-sm' data-bs-toggle="modal" data-bs-target="#editUserInfoModal" onClick={() => editUserInfo(userInfo)}  >
                            Edit </button>
                        </td>
                    </tr>
                    )}
                </tbody>
            </table>
        </div>
        <div className="modal" id='editUserInfoModal' tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" style={{color: 'rgba(60,106,176,0.9)'}}>Edit User</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <fetcher.Form
                        method="post"
                    >
                        <div className="mb-3">
                            <label htmlFor="user_first_name" className="form-label">First Name <span style={{color: 'red'}}>*</span></label>
                            <input type={"text"} className="form-control" id="user_first_name" name="user_first_name" required value={userFirstName || ''}
                            onChange={e => setUserFirstName(e.target.value)}></input>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="user_last_name" className="form-label">Last Name <span style={{color: 'red'}}>*</span></label>
                            <input type={"text"} className="form-control" id="user_last_name" name="user_last_name" 
                            required value={userLastName || ''}
                                onChange={e => setUserLastName(e.target.value)}>
                            </input>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="user_email" className="form-label">Email <span style={{color: 'red'}}>*</span></label>
                            <input type={"text"} className="form-control" id="user_email" name="user_email" required value={userEmail || ''}
                            onChange={e => setUserEmail(e.target.value)}></input>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="user_password" className="form-label">Password <span className='text-muted' style={{fontSize: '.80rem'}}>(Leave blank to keep the same password)</span></label>
                            <input type={"password"} className="form-control" id="user_password" name="user_password" 
                                value={userPassword || ''}
                                onChange={e => setUserPassword(e.target.value)}>
                            </input>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="confirm_user_password" className="form-label">Confirm Password</label>
                            <input type={"password"} className="form-control" id="confirm_user_password" name="confirm_user_password" 
                             value={confirmUserPassword || ''}
                                onChange={e => setConfirmUserPassword(e.target.value)}>
                            </input>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="is_admin" className="form-label">Admin <span style={{color: 'red'}}>*</span></label>
                            <input type={"checkbox"} aria-describedby="is_admin"className="form-check-input ms-2" id="is_admin" name="is_admin" checked={isAdmin} value={isAdmin || false}
                                onChange={e => setIsAdmin(!isAdmin)}>
                            </input>
                        </div>
                        <div className="mb-3">
                            <input type={"text"} id="user_id" name="user_id" hidden value={userId} readOnly />

                        </div>         
                        <button type="submit" className='btn btn-hermes me-3' disabled={navigation.state === 'submitting'}  data-bs-dismiss="modal">Update</button>    
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    </fetcher.Form>
                </div>
                <div className="modal-footer">
                </div>
                </div>
            </div>
        </div>
        </>
    )

}

export async function action ({ request }) {

    const formData = await request.formData();

    let msg = {
        status: '',
        code: 0
    };


    const userEmail = formData.get('user_email');
    const userFirstName = formData.get('user_first_name');
    const userLastName = formData.get('user_last_name');
    const userPassword = formData.get('user_password');
    const confirmPassword = formData.get('confirm_user_password');
    const isAdmin = formData.get('is_admin');
    const userId = formData.get('user_id');


    let admin = false;

    if (userPassword || userPassword !== '') {
        if (userPassword !== confirmPassword) {
            msg.status = 'Passwords do not match';
            msg.code = 600
            return msg;
        }
        if (!checkPassword(userPassword)) {
            msg.status = 'Password must be 8 characters long, include 1 lowercase and 1 uppercase letter, 1 number and 1 special character';
            msg.code = 600;
            return msg;
        }
    }

    if (isAdmin) {
        admin = true;
    }

    const data = {
        user_id: userId,
        user_email: userEmail,
        user_first_name: userFirstName,
        user_last_name: userLastName,
        user_password: userPassword,
        is_admin: admin
    }


    try {
        const numUpdated = await updateUser(data);
        msg.status = numUpdated;
        msg.code = 200;
        return msg;
        // return newRaceId;
    } catch (err) {
        console.log(err);
        if (err.code >= 400) {
            msg.status = err.status.error;
            msg.code = err.code;
            return msg;
        }
    }
}


export async function loader () {
    

    let authInfo = await JSON.parse(localStorage.getItem('authInfo'));
    const today = new Date();
    if (authInfo === null || authInfo?.expiry < today ) {
        localStorage.setItem('authInfo', null);
        return redirect ('/login');
    }

    try {
        const userList = await getUserList();
        return userList;
    } catch (err) {
        if (err.status === 401 ) {
            return redirect('/login');
        }
        return err; 
    }
}
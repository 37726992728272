import { Link } from "react-router-dom";


export default function StandingsDisplay(rankings) {
    
    return (
        <>
            <div className="row">
                <div className="col-12 col-xl-2"></div>
                <div className="col-12 col-xl-6 mx-auto">
                { rankings?.props.map((rank, index) =>
                <div className={"card mb-5 " + (rank.num_races >=  rankings.seriesInfo[0].min_races ? "border border-4 border-success" : "")} key={rank.participant_series_id}>
                    <h6 className="card-header">{rank.first_name + ' ' + rank.last_name}</h6>
                    <div className="card-body">
                        <div className="hstack gap-2">
                            <div className="pe-2 standings-small-titles">{rank.gender}:{rank.participant_series_age}</div>
                            <div className="vr"></div>
                            <div className="ps-2 standings-small-titles">{rank.city}, {rank.state_name} </div>
                            <div className="vr"></div>
                            <div className={"standings-small-titles " + (rank.num_races >= rankings.seriesInfo[0].min_races ? "text-success" : "")}>Total Races:</div>
                            <div className={ rank.num_races >= rankings.seriesInfo[0].min_races ? "text-success" : ""}>{rank.num_races}</div>
                        </div>
                        <hr/>
                        <div className="hstack gap-2">
                            <div className="text-muted standings-small-titles">Gender Place:</div>
                            { rank.gender_place === 10000 ? <div style={{color: "red"}}>DNQ</div> : <div>{rank.gender_place}</div>}
                            <div className="vr"></div>
                            { rankings.showAgeGroups ?
                            <>
                                <div className="text-muted standings-small-titles">Age Place</div>
                                { rank.age_group_place === 10000 ? <div style={{color: "red"}}>DNQ</div> : <div>{rank.age_group_place > 0 ? rank.age_group_place : '-'}</div>}
                                            
                            </> : ""
                            }
                        </div>
                        <hr/>
                        <p className="text-center text-muted m-0 p-0 fw-bold standings-small-titles">Total Points</p>
                        <h1 className="card-title text-center font-monospace standings-large-items">{rank.overall_points}</h1>
                        <hr/>
                        <div className="d-grid col mx-auto">
                            <Link className='btn btn-hermes' to={`/standing-detail/${rank.series_id}/${rank.participant_series_id}`}>Race Details</Link>
                        </div>
                    </div>
                </div>
                )}
                </div>
                <div className="col-12 col-xl-2"></div>
            </div>
        </>
    )

}